import { FC } from 'react';
import { Link, NavLink, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { differenceInDays, parseISO } from 'date-fns';
import { Root } from '@radix-ui/react-dialog';

import { Container } from '@app/shared/ui/container';
import { BxGridAltIcon, CrossIcon, RightArrowAltIcon } from '@app/components/ui/icons';
import { route_path } from '@app/utils/route_path';
import { useLoadPresentationQuery } from '@app/store/api/presentation.api';
import { useGetThemeInitQuery } from '@app/store/api/user.api';
import { cn } from '@app/app/lib';
import { StageHeaderPopover } from '@app/widgets/header/ui/modals/stage-header-popover';
import { Skeleton } from '@app/shared/ui/skeleton';
import { RenameModal } from '@app/widgets/rename-dialog';

import { Button } from '@app/shared/ui/button';
import t from '@lib/lng';
import { cvaIcon } from '@app/shared/ui/elements';
import { BurgerButton } from '@app/shared/ui/burger-button';
import { useLogOutMutation } from '@app/store/api/user.api';
import type { CommonOutletContext } from '@app/pages/common.outlet';
import { useSlides } from '@app/widgets/canvas/api/hooks/useSlides';

import { useSlideStore } from '@app/widgets/canvas/store/slide';

import { screens } from '../../../../../../config/tailwind';

import { useHeaderStore } from '../model/header.store';

import { MobileMenu } from './mobile-menu';

import {
  cvaBurgerButton,
  cvaActionsButtons,
  cvaArrowIcon,
  cvaContent,
  cvaNavigation,
  cvaNavigationItem,
  cvaRoot,
  cvaPresentationName,
} from './stage-header-styles';

import { cvaMenuItem } from './header-styles';
type StageHeaderProps = {
  isWorkspaceVariant: boolean;
};

export const StageHeader: FC<StageHeaderProps> = ({ isWorkspaceVariant }) => {
  const params = useParams();

  const isMobile = useMediaQuery(`(max-width: ${screens.screens['semiMd-max'].max})`);
  const { context } = useOutletContext<CommonOutletContext>();
  const { isMenuOpen, toggleHeaderMenuOpen } = useHeaderStore();
  const handleToggleMenuOpen = () => toggleHeaderMenuOpen(!isMenuOpen);
  const handleClose = () => toggleHeaderMenuOpen(false);
  const { setWorkspaceDescription, workspaceDescription } = useSlideStore();
  const { workspaceId } = useParams();
  const { data: presentationData } = useLoadPresentationQuery(
    {
      projectId: String(params.projectId),
    },
    { skip: !params.projectId },
  );

  const isFinalizePage = () => {
    // Check if the current path matches the workspace/:workspaceId/ route
    if (params.workspaceId) {
      const currentPath = `${route_path.workspace}${params.workspaceId}`;
      // Normalize paths by removing trailing slashes for comparison
      const normalizedCurrentPath = currentPath.replace(/\/+$/, '');
      const normalizedPathname = location.pathname.replace(/\/+$/, '');
      return normalizedPathname === normalizedCurrentPath;
    }
    return false;
  };

  // Add useSlides for workspace variant
  const { isLoading: isWorkspaceSlidesLoading } = useSlides(
    isWorkspaceVariant && isFinalizePage() ? String(params.workspaceId) : undefined,
  );

  // Get theme data to check if there's only one theme
  const { data: themesData } = useGetThemeInitQuery();
  const hasSingleTheme =
    themesData?.result?.options?.themes && themesData.result.options.themes.length === 1;

  const isPptxSlides = Object.values(presentationData?.result?.slides_info ?? {}).some(
    (slide) => slide?.slide_type === 'pptx',
  );

  const hasParams = params.projectId !== undefined || params.workspaceId !== undefined;
  const isWorkspace = params.workspaceId !== undefined;

  const isOptionInitialized = !!(presentationData?.result?.process?.option ?? []).find(
    (option) => Object.keys(option).length > 0,
  );

  const [logoutQuery] = useLogOutMutation();
  const navigate = useNavigate();

  const logOutHandler = () => {
    logoutQuery().then(() => {
      handleClose();
      navigate(route_path.main);
    });
  };

  const daysLeft =
    context?.product_balance &&
    context?.product_balance.is_active &&
    context?.product_balance.product.is_trial &&
    context?.product_balance.expire
      ? differenceInDays(parseISO(context?.product_balance.expire), new Date())
      : null;

  const handleRename = (newName: string) => {
    setWorkspaceDescription(newName);
  };

  return (
    /* TODO: Replace font-inter */ /* TODO: pb-2 is a temporary margin while there is no presentation name */
    <div className={cn(cvaRoot({ isWorkspaceVariant }), 'font-inter pb-2')}>
      <Container>
        <div className={cvaContent()}>
          <nav className={cvaNavigation()}>
            <NavLink to={route_path.projects} className={cvaNavigationItem({ isActive: false })}>
              My presentations
            </NavLink>

            <RightArrowAltIcon className={cvaArrowIcon()} />

            <NavLink
              to={isWorkspace ? route_path.workspaceCreate : route_path.create}
              className={cvaNavigationItem({ isActive: false })}
            >
              Create
            </NavLink>

            {isPptxSlides && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                <NavLink
                  to={isWorkspaceVariant ? route_path.workspaceUploadDraft : route_path.uploadDraft}
                  className={({ isActive }) => cvaNavigationItem({ isActive })}
                >
                  Upload draft
                </NavLink>
              </>
            )}

            {/* TODO: No route */}
            {/*<RightArrowAltIcon className={cvaArrowIcon()} />*/}
            {/*<NavLink to="" className={cvaNavigationItem({ isActive: false })}>*/}
            {/*  Input content*/}
            {/*</NavLink>*/}

            {/* TODO: No route */}
            {/*<RightArrowAltIcon className={cvaArrowIcon()} />*/}
            {/*<NavLink to="" className={cvaNavigationItem({ isActive: false })}>*/}
            {/*  Content preview*/}
            {/*</NavLink>*/}

            {hasParams && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                {hasSingleTheme ? (
                  <span
                    className={cn(
                      cvaNavigationItem({ isActive: false }),
                      'opacity-50 cursor-not-allowed',
                    )}
                  >
                    Style design
                  </span>
                ) : (
                  <NavLink
                    to={
                      isWorkspace
                        ? route_path.workspaceSetupStyle +
                          String(params.workspaceId) +
                          '?skipQueryFrom=1'
                        : route_path.setupStyle + String(params.projectId) + '?skipQueryFrom=1'
                    }
                    className={({ isActive }) => cvaNavigationItem({ isActive })}
                  >
                    Style design
                  </NavLink>
                )}
              </>
            )}

            {hasParams && isWorkspace && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                <NavLink
                  to={route_path.workspace + String(params.workspaceId)}
                  className={({ isActive }) => cvaNavigationItem({ isActive })}
                >
                  {isPptxSlides ? 'Finalize' : 'Create & Edit slides'}
                </NavLink>
              </>
            )}

            {hasParams && !isWorkspace && isOptionInitialized && (
              <>
                <RightArrowAltIcon className={cvaArrowIcon()} />
                <NavLink
                  to={route_path.project + String(params.projectId)}
                  className={({ isActive }) => cvaNavigationItem({ isActive })}
                >
                  {isPptxSlides ? 'Finalize' : 'Create & Edit slides'}
                </NavLink>
              </>
            )}
          </nav>

          <div className={cvaActionsButtons()}>
            {/* Create new button */}
            <Button variant="outline" borderRadius="3xl" asChild>
              <Link to="/create/">{t.Create}</Link>
            </Button>

            {/* My projects*/}
            <NavLink
              to="/projects/"
              className={({ isActive }) =>
                cvaMenuItem({
                  isActive,
                  isTransparent: false,
                  isDotHidden: true,
                })
              }
            >
              <BxGridAltIcon className={cvaIcon()} />
              <span>My presentations</span>
            </NavLink>
          </div>

          {isMobile ? (
            <Root open={isMenuOpen}>
              <BurgerButton
                isOpen={isMenuOpen}
                activeIcon={CrossIcon}
                onClick={handleToggleMenuOpen}
                className={cvaBurgerButton()}
              />
              <MobileMenu daysLeft={daysLeft} onClose={handleClose} onLogOut={logOutHandler} />
            </Root>
          ) : (
            <StageHeaderPopover />
          )}
        </div>

        {isWorkspaceVariant && isWorkspaceSlidesLoading ? (
          <Skeleton className="relative z-[100] h-6 mb-1 max-w-[12rem] w-full" />
        ) : (
          isFinalizePage() && (
            <div className="flex items-center gap-2">
              <h2 className={cvaPresentationName()}>
                {workspaceDescription !== '' ? workspaceDescription : 'Untitled'}
              </h2>
              <RenameModal
                initialName={workspaceDescription !== '' ? workspaceDescription : 'Untitled'}
                onRename={handleRename}
                workspaceId={workspaceId ?? ''}
                key={workspaceDescription}
              />
            </div>
          )
        )}
      </Container>
    </div>
  );
};
