import { Stage } from '../types';

type StageStatusIcons = {
  created: string;
  running: string;
  fail: string;
  success: string;
};

type StagesStatuses = {
  [key in Stage['type']]: StageStatusIcons;
};

export const stagesStatuses: StagesStatuses = {
  compose: {
    created: '/static/i/gif/processing_icon5.gif',
    running: '/static/i/gif/processing_icon6.gif',
    fail: '❌',
    success: '✅',
  },
  parse: {
    created: '/static/i/gif/processing_icon1.gif',
    running: '/static/i/gif/processing_icon2.gif',
    fail: '❌',
    success: '✅',
  },
  thumbnail: {
    created: '/static/i/gif/processing_icon3.gif',
    running: '/static/i/gif/processing_icon4.gif',
    fail: '❌',
    success: '✅',
  },
  markup: {
    created: '/static/i/gif/processing_icon3.gif',
    running: '/static/i/gif/processing_icon4.gif',
    fail: '❌',
    success: '✅',
  },
  editableSlide: {
    created: '/static/i/gif/processing_icon3.gif',
    running: '/static/i/gif/processing_icon4.gif',
    fail: '❌',
    success: '✅',
  },
  cloning: {
    created: '/static/i/gif/processing_icon3.gif',
    running: '/static/i/gif/processing_icon4.gif',
    fail: '❌',
    success: '✅',
  },
};
