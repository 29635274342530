export const CANVAS_BUBBLES_NAMES = {
  updateBackups: 'slideCanvas::updateBackups',
};

export const CANVAS_GROUP_BG_COLOR = '#24F1BA';
export const CANVAS_SCALE = 1.22;
export const BASE_CANVAS_DIMENSIONS = { width: 960, height: 540 }; // Back needed this parameters
export const MAX_CANVAS_DIMENSIONS = { width: 1172, height: 659 };
export const BASE_SCALE = 1.22;
export const CANVAS_DIMENSIONS = { width: 1172, height: 659 };
export const OFFSET_Y_MENU = 59;
export const OFFSET_Y_ROTATION_CONTROL = OFFSET_Y_MENU + 20;
export const SELECTORS = {
  topCanvas: '[data-fabric="top"]',
  contextMenu: '[data-js-canvas-context-menu]',
  canvas: (canvasID: string) => `[data-canvas-id="${canvasID}"]`,
};

export const ACTIVE_OBJECT_OPACITY = 0.5;

export const SELECTION_STYLES = {
  DEFAULT: {
    borderColor: '#00BA88',
    cornerColor: '#FFFFFF',
    cornerStrokeColor: '#00BA88',
    cornerSize: 10,
    transparentCorners: true,
    cornerStrokeWidth: 2,
    borderOpacityWhenMoving: 0.8,
  },
  HOLLOW_DIAMOND: {
    borderColor: '#00BA88',
    cornerColor: '#FFFFFF',
    cornerStrokeColor: '#00BA88',
    cornerSize: 10,
    transparentCorners: true,
    cornerStyle: 'rect',
    cornerStrokeWidth: 2,
    rotateCorners: true,
    borderOpacityWhenMoving: 0.8,
  },
  HOLLOW_SQUARE: {
    borderColor: '#00BA88',
    cornerColor: '#FFFFFF',
    cornerStrokeColor: '#00BA88',
    cornerSize: 10,
    transparentCorners: true,
    cornerStyle: 'rect',
    cornerStrokeWidth: 2,
    rotateCorners: false,
    borderOpacityWhenMoving: 0.8,
  },
  HOLLOW_CIRCLE: {
    borderColor: '#00BA88',
    cornerColor: '#00BA88',
    cornerStrokeColor: '#00BA88',
    cornerSize: 8,
    transparentCorners: false,
    cornerStyle: 'circle',
    cornerStrokeWidth: 1,
    borderOpacityWhenMoving: 0.8,
  },
};

export const CANVAS_EVENTS = {
  UPDATE_CONTEXT_MENU: 'slideCanvas::updateContextMenu',
};
