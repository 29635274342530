/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuType } from '../types/types';

// Minimum gap between objects when distributing
const MIN_GAP = 10;

/**
 * Aligns objects within the active selection group relative to its boundaries
 * @param alignment - Alignment type
 * @param canvas - Canvas instance
 * @param canvasId - Canvas ID
 * @param addBackup - Function to create a backup
 */
export const alignObjects = ({
  alignment,
  canvas,
  canvasId,
  addBackup,
}: {
  alignment: 'left' | 'right' | 'top' | 'bottom' | 'distributeHorizontal' | 'distributeVertical';
  canvas: any;
  canvasId: string;
  addBackup: (canvasId: string, data: any) => void;
  item?: { type: MenuType };
  targetIds?: string[];
}) => {
  // Get the active object (selection group)
  const activeObject = canvas._activeObject;

  // If there's no active object, do nothing
  if (!activeObject) return;

  // Get objects within the active group
  const activeObjects = canvas.getActiveObjects();
  if (activeObjects.length === 0) return;

  // If the active object is a selection group
  if (activeObject.type.toLowerCase() === 'activeselection') {
    // Horizontal alignment
    if (alignment === 'left') {
      // Align to the left relative to the left boundary of the group
      activeObjects.forEach((obj: any) => {
        // In a selection group, (0,0) is at the center of the group, and the left boundary is at -width/2
        // Consider the object's originX and its own width
        const objWidth = (obj.width || 0) * (obj.scaleX || 1);
        const leftOffset = obj.originX === 'center' ? objWidth / 2 : 0;
        obj.set({ left: -activeObject.width / 2 + leftOffset });
      });
    } else if (alignment === 'right') {
      // Align to the right relative to the right boundary of the group
      activeObjects.forEach((obj: any) => {
        // Calculate the object's width considering scaling
        const objWidth = (obj.width || 0) * (obj.scaleX || 1);
        const rightOffset = obj.originX === 'center' ? objWidth / 2 : objWidth;
        console.debug('rightOffset!', rightOffset);
        obj.set({ left: activeObject.width / 2 - rightOffset });
      });
    }
    // Vertical alignment
    else if (alignment === 'top') {
      // Align to the top relative to the top boundary of the group
      activeObjects.forEach((obj: any) => {
        // In a selection group, the top boundary is at a distance of -height/2 from the center
        // Consider the object's originY and its own height
        const objHeight = (obj.height || 0) * (obj.scaleY || 1);
        const topOffset = obj.originY === 'center' ? objHeight / 2 : 0;
        obj.set({ top: -activeObject.height / 2 + topOffset });
      });
    } else if (alignment === 'bottom') {
      // Align to the bottom relative to the bottom boundary of the group
      activeObjects.forEach((obj: any) => {
        // Calculate the object's height considering scaling
        const objHeight = (obj.height || 0) * (obj.scaleY || 1);
        const bottomOffset = obj.originY === 'center' ? objHeight / 2 : objHeight;

        // In a selection group, the bottom boundary is at a distance of height/2 from the center
        obj.set({ top: activeObject.height / 2 - bottomOffset });
      });
    }
    // Horizontal distribution
    else if (alignment === 'distributeHorizontal') {
      // Need at least 2 objects for distribution
      if (activeObjects.length < 2) return;

      // Sort objects horizontally (left to right)
      const sortedObjects = [...activeObjects].sort((a: any, b: any) => a.left - b.left);

      // Calculate the total width of all objects considering scaling
      const totalObjectsWidth = sortedObjects.reduce((sum, obj: any) => {
        const objWidth = (obj.width || 0) * (obj.scaleX || 1);
        return sum + objWidth;
      }, 0);

      // Calculate the available space within the active object
      const availableWidth = activeObject.width * activeObject.scaleX;

      // Calculate the gap between objects
      let gap = (availableWidth - totalObjectsWidth) / (sortedObjects.length - 1);

      // If the gap is less than the minimum, use the minimum
      if (gap < MIN_GAP) {
        gap = MIN_GAP;
      }

      // Find the center of the group along Y
      const groupCenterY = 0; // In local coordinates of the group, the center is at (0,0)

      // Initial X position (left boundary of the group)
      let currentX = -activeObject.width / 2;

      // Distribute objects evenly horizontally and center them along Y
      sortedObjects.forEach((obj: any) => {
        const objWidth = (obj.width || 0) * (obj.scaleX || 1);
        const objHeight = (obj.height || 0) * (obj.scaleY || 1);

        // Consider the object's originX
        const leftOffset = obj.originX === 'center' ? objWidth / 2 : 0;

        // Consider the object's originY for centering along Y
        const topOffset = obj.originY === 'center' ? 0 : objHeight / 2;

        // Set the new position
        obj.set({
          left: currentX + leftOffset,
          top: groupCenterY + topOffset,
        });

        // Increase the current X position by the object's width and gap
        currentX += objWidth + gap;
      });
    }
    // Vertical distribution
    else if (alignment === 'distributeVertical') {
      // Need at least 2 objects for distribution
      if (activeObjects.length < 2) return;

      // Sort objects vertically (top to bottom)
      const sortedObjects = [...activeObjects].sort((a: any, b: any) => a.top - b.top);

      // Calculate the total height of all objects considering scaling
      const totalObjectsHeight = sortedObjects.reduce((sum, obj: any) => {
        const objHeight = (obj.height || 0) * (obj.scaleY || 1);
        return sum + objHeight;
      }, 0);

      // Calculate the available space within the active object
      const availableHeight = activeObject.height * activeObject.scaleY;

      // Calculate the gap between objects
      let gap = (availableHeight - totalObjectsHeight) / (sortedObjects.length - 1);

      // If the gap is less than the minimum, use the minimum
      if (gap < MIN_GAP) {
        gap = MIN_GAP;
      }

      // Find the center of the group along X
      const groupCenterX = 0; // In local coordinates of the group, the center is at (0,0)

      // Initial Y position (top boundary of the group)
      let currentY = -activeObject.height / 2;

      // Distribute objects evenly vertically and center them along X
      sortedObjects.forEach((obj: any) => {
        const objHeight = (obj.height || 0) * (obj.scaleY || 1);
        const objWidth = (obj.width || 0) * (obj.scaleX || 1);

        // Consider the object's originY
        const topOffset = obj.originY === 'center' ? objHeight / 2 : 0;

        // Consider the object's originX for centering along X
        const leftOffset = obj.originX === 'center' ? 0 : objWidth / 2;

        // Set the new position
        obj.set({
          top: currentY + topOffset,
          left: groupCenterX + leftOffset,
        });

        // Increase the current Y position by the object's height and gap
        currentY += objHeight + gap;
      });
    }

    // Create a backup and redraw the canvas
    addBackup(canvasId, canvas.toJSON());
    canvas.renderAll(); // Redraw the canvas after changes
  }
};
