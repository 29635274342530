export const serverPath = {
  main: '/',
  process: '/process/',
  projects: '/projects/',
  project: '/project/',
  share: '/share/',
  shareInfo: '/share-info/',
  feed: '/feed/',
  saveSurveyData: '/save-survey-data/',
  wondercheck: '/wondercheck/',
  staticISample3: '/static/i/sample.pptx?3',
  queuePing: '/queue-ping/',
  removeVisual: '/remove-visual/',
  setColor: '/set-color/',
  remake: '/remake/',
  download: '/download/',
  downloadPing: '/download-ping/',
  upload: '/upload/',
  uploadLogo: '/upload-logo/',
  deleteLogo: '/logo-delete/',
  searchMedia: '/search-media/',
  init: {
    common: '/init/common/',
    user: '/init/user/',
    theme: '/init/theme/',
    product: '/init/product/',
  },
  stripe: {
    stripe_subscription_cancel: '/stripe/subscription/cancel/',
  },
  team: {
    teamRequest: '/team/request/',
  },
  user: {
    logout: '/logout/',
    login: '/login/',
    passwordReset: '/password-reset/',
    register: '/register/',
    password: '/password/',
    updateOptions: '/update-options/',
    sendEmailConfirm: '/send-email-confirm/',
    purchase: '/purchase/',
  },
  draft: {
    draftCreate: '/create-draft/',
    draftUpdateParams: '/draft/update-params/',
    draftProcess: '/draft/process/',
    draftRemoveVisual: '/draft/remove-visual/',
    draftCreateV2: '/draft/create/',
    draftRemoveSlide: '/draft/remove-slide/',
    draftCloneSlide: '/draft/clone-slide/',
  },
  slideShooting: {
    slideShootingVersion: '/slide-shooting/versions/',
    slideShootingTheme: '/slide-shooting/themes/',
    slideShootingSetOptions: '/slide-shooting/set-option/',
    slideShootingProcess: '/slide-shooting/process/',
    slideShootingQueuePing: '/slide-shooting/queue-ping/',
    slideShootingFiles: '/slide-shooting/files/',
  },
  slide: {
    addSlide: '/add-slide/',
    cloneSlide: '/clone-slide/',
    remakeSlide: '/remake-slide/',
    processSlide: '/process-slide/',
    removeSlide: '/remove-slide/',
    regenSlide: '/regen-slide/',
    uploadImages: '/upload-images/',
    removeVisual: '/remove-visual/',
  },
  logo: {
    logoDelete: '/logo-delete/',
    logoUpload: '/upload-logo/',
  },
  preset: {
    deletePreset: '/delete-preset/',
  },
  theme: {
    initTheme: 'init/theme',
  },
  workspace: {
    update: '/workspace/update/',
    downloadPresentationPrepare: '/workspace/download/prepare/',
    slidesDataSSE: '/workspace/slide/queue/',
    downloadPresentationSSE: '/workspace/download/ready/',
    downloadPresentationReady: '/workspace/download/',
    initialSlidesData: '/workspace/generation-info/',
    uploadPPTX: '/workspace/upload/',
    process: '/workspace/process/',
    createEmpty: '/workspace/create-empty/',
    addSlide: '/workspace/slide/add/',
    deleteSlide: '/workspace/slide/remove/',
    copySlide: '/workspace/slide/copy/',
    moveSlide: '/workspace/slide/move/',
    saveSlideConfig: '/workspace/slide/save-config/',
    getSlideConfig: '/workspace/slide/get-config/',
    share: '/workspace/share/',
    getMarkup: (workspaceId: string, slideId: string) =>
      `/workspace/slide/get-markup/${workspaceId}/${slideId}/`,
    processSlide: (workspaceId: string, slideId: number) =>
      `/workspace/slide/process/${workspaceId}/${slideId}/`,
    saveThumbnail: (workspaceId: string) => `/workspace/save-thumbnail/${workspaceId}/`,
  },
};
