import { Canvas, util, FabricImage, Path, TMat2D, Point } from 'fabric';

// TODO: Implement when have different masks Instantly apply mask to image
export const applyImageMask = (canvas: Canvas) => {
  const img = canvas.getActiveObject() as FabricImage;

  if (!img) return;

  img.set({
    // clipPath: getImageMask(shape, img.width, img.height),
    dirty: true,
    opacity: 1,
    lockRotation: false,
    selectable: true,
    originX: 'center',
    originY: 'center',
  });

  canvas.renderAll();
};

export const editImageCrop = async (canvas: Canvas, img: FabricImage, callback: () => void) => {
  if (img.clipPath === undefined) return;
  // img.set({ clipPath: null });
  // console.log(img, 'img');

  // const { width: originalWidth, height: originalHeight } = img.getOriginalSize();

  // console.log(originalHeight, originalWidth, 'ORGIGINAL');
  // const scaledWidth = img.getScaledWidth();
  // const scaledHeight = img.getScaledHeight();
  // console.log(scaledWidth, 'scaledWidth', scaledHeight, 'scaledHeight');

  const clipPath = img.clipPath as Path;
  // console.log(clipPath);
  const path = clipPath.path;
  // console.log(path, 'InitialPath');
  // console.log(util.getPathSegmentsInfo(path));
  const newPath = util.transformPath(
    path,
    [clipPath.scaleX, 0, 0, clipPath.scaleY, 0, 0],
    img.getCenterPoint(),
  );

  // console.log(newPath, 'newPath');
  // console.log(newPath, 'cropAreaPath');

  const cropArea = new Path(newPath, {
    id: 'crop-rect',
    angle: img.angle,
    stroke: '#00BA88',
    strokeWidth: 1,
    lockRotation: true,
    selectable: false,
    evented: false,
    originX: 'center',
    originY: 'center',
    left: img.getX(),
    top: img.getY(),
    width: clipPath.getScaledWidth(),
    height: clipPath.getScaledHeight(),
    fill: null,
    scaleX: img.scaleX,
    scaleY: img.scaleY,
    flipX: true,
    zIndex: 9999999999,
  });
  console.log(cropArea, 'cropArea');
  canvas.add(cropArea);

  // const leftAdjustment = (img.width / 2) * img.scaleX - (originalWidth / 2) * img.scaleX;
  // const topAdjustment = (img.height / 2) * img.scaleY - (originalHeight / 2) * img.scaleY;
  //
  // console.log(img.clipPath);
  // console.log('top', img.top, topAdjustment, 'left', img.left, leftAdjustment);
  // console.log(img.getObjectScaling(), 'objectScaling');
  // console.log(img.getViewportTransform());
  //

  img.set({
    clipPath: null,
    //   //   left: img.left + leftAdjustment,
    //   //   top: img.top + topAdjustment,
    //   //   width: originalWidth,
    //   //   height: originalHeight,
    dirty: false,
    opacity: 0.5,
    lockRotation: true,
    selectable: true,
    originX: 'center',
    originY: 'center',
  });

  img.setCoords();
  // // img.on('moving', constrainedMovingHandler);
  //
  canvas.renderAll();
  // callback();
  //
  img.once('deselected', () => {
    const transPath = util.transformPath(
      path,
      util.composeMatrix({
        scaleX: 1,
        scaleY: 1,
      }),
      new Point({ x: 0, y: 0 }),
    );

    // console.log(path, 'originalPath');
    // console.log(transPath, 'transPath');
    img.set({
      clipPath: new Path(transPath, { ...clipPath, left: 0, top: 0 }),
    });

    //   const clip = new Path(newPath2, {
    //     absolutePositioned: false,
    //     fill: 'rgb(1,222,3)',
    //     left: 0,
    //     top: 0,
    //     width: (cropArea.width * cropArea.scaleX) / img.scaleX,
    //     height: (cropArea.height * cropArea.scaleY) / img.scaleY,
    //     originX: 'center',
    //     originY: 'center',
    //   });
    //   console.log(clip);
    //   img.set({
    //     clipPath: clip,
    //     top: cropArea.top,
    //     left: cropArea.left,
    //     width: (cropArea.width * cropArea.scaleX) / img.scaleX,
    //     height: (cropArea.height * cropArea.scaleY) / img.scaleY,
    //     opacity: 1,
    //     lockRotation: false,
    //     dirty: true,
    //     selectable: true,
    //     originX: 'center',
    //     originY: 'center',
    //   });

    canvas.remove(cropArea);
    canvas.renderAll();
    // console.log(img, 'imgMasked');
    // callback();
  });
};

export const removeMask = (canvas: Canvas) => {
  const img = canvas.getActiveObject() as FabricImage;

  if (!img) return;

  const { width: originalWidth, height: originalHeight } = img.getOriginalSize();

  img.set({
    clipPath: null,
    cropX: null,
    cropY: null,
    width: originalWidth,
    height: originalHeight,
  });

  canvas.renderAll();
};

// TODO: Render polygon
export const deleteMaskImage = (canvas: Canvas, image: FabricImage) => {
  const clipPathMask = image.clipPath;

  if (clipPathMask !== undefined) {
    // const clipPathMask = getImageMask(
    //   image.maskShape,
    //   image.getScaledWidth(),
    //   image.getScaledHeight(),
    //   {
    //     // TODO: No type info stored about object
    //     top: image.getY(),
    //     left: image.getX(),
    //     originX: 'center',
    //     originY: 'center',
    //     id: image.id,
    //     layer: image.layer,
    //     zIndex: image.zIndex,
    //     selectable: true,
    //   },
    // );

    // canvas.add(clipPathMask);
    canvas.remove(image);
    canvas.renderAll();
    requestAnimationFrame(() => {
      // canvas.setActiveObject(clipPathMask);
    });
  }
};
