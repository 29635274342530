import { create } from 'zustand';

export type IconHub = {
  preview: string;
  image: string;
  source_ext: string;
};

export type ImageHub = {
  preview: string;
  image: string;
  source_ext: string;
  media_type?: string;
};

type MediaHubModalsStore = {
  isImageHubOpen: boolean;
  isIconsHubOpen: boolean;
  icons: IconHub[];
  images: ImageHub[];
};

type MediaHubModalsActions = {
  setImageHubOpen: (isOpen: MediaHubModalsStore['isImageHubOpen']) => void;
  setIconsHubOpen: (isOpen: MediaHubModalsStore['isIconsHubOpen']) => void;
  setIcons: (icons: IconHub[]) => void;
  setImage: (icons: ImageHub[]) => void;
  clearStore: () => void;
};

const defaultState: MediaHubModalsStore = {
  isImageHubOpen: false,
  isIconsHubOpen: false,
  icons: [],
  images: [],
};

const useMediaHubModalsStore = create<MediaHubModalsStore & MediaHubModalsActions>((set) => ({
  ...defaultState,
  setIconsHubOpen: (isOpen) => set(() => ({ isIconsHubOpen: isOpen })),
  setImageHubOpen: (isOpen) => set(() => ({ isImageHubOpen: isOpen })),
  setIcons: (icons) => set(() => ({ icons })),
  setImage: (images) => set(() => ({ images })),
  clearStore: () => set(() => ({ ...defaultState })),
}));

export { useMediaHubModalsStore };
