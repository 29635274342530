import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { cookie } from '@app/app/lib/cookie';

import { Provider } from 'react-redux';

import { Router } from '@app/app/router';
import { TooltipProvider } from '@app/shared/ui/tooltip';

import ErrorBoundary from './common/errorBoundary';

import { ToastProvider } from './hooks/use-toast-provider';
import { store } from './store';
import { LoadingScreen } from './components/template';
import { route_path } from './utils/route_path';
import { Toaster } from '@app/shared/ui/sonner';

const CookiePopup = lazy(() => import('./common/cookiePopup.js'));
const PaymentCancelNotify = lazy(() => import('./modals/paymentCancelNotify.js'));
const PaymentSuccessNotify = lazy(() => import('./modals/paymentSuccessNotify.js'));

export default function app() {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCookiePopup: !cookie.get('cookie_accepted'),
      showPaymentSuccessNotify: false,
      showPaymentErrorNotify: false,
      showPaymentCancelNotify: false,
      paymentCancelNotifyBackUrl: route_path.pricing,
    };
  }

  componentDidMount() {
    if (window.location.search.includes('success')) {
      this.setState({ showPaymentSuccessNotify: true });
      window.history.replaceState(
        {},
        document.title,
        window.location.href.replace(/(\?|&)+success/, ''),
      );
    } else if (window.location.search.includes('error')) {
      this.setState({ showPaymentErrorNotify: true });
      window.history.replaceState(
        {},
        document.title,
        window.location.href.replace(/(\?|&)+error/, ''),
      );
    } else {
      if (window.location.search.includes('cancel')) {
        this.setState({ showPaymentCancelNotify: true });
        window.history.replaceState(
          {},
          document.title,
          window.location.href.replace(/(\?|&)+cancel/, ''),
        );
      }
      if (window.sessionStorage.getItem('gotoProduct')) {
        this.setState({
          showPaymentCancelNotify: true,
          paymentCancelNotifyBackUrl: JSON.parse(window.sessionStorage.getItem('gotoProduct')).url,
        });
      }
    }
    window.sessionStorage.removeItem('gotoProduct');
  }

  render() {
    const { context } = this.state;
    return (
      <ErrorBoundary>
        <Suspense fallback={<LoadingScreen />}>
          <Provider store={store}>
            <TooltipProvider>
              <ToastProvider>
                <BrowserRouter
                  basename="/"
                  future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                  }}
                >
                  <Router context={context} />

                  {this.state.showCookiePopup && (
                    <CookiePopup
                      close={() => {
                        this.setState({
                          showCookiePopup: false,
                        });
                      }}
                    />
                  )}
                  {this.state.showPaymentSuccessNotify && (
                    <PaymentSuccessNotify
                      close={() => {
                        this.setState({
                          showPaymentSuccessNotify: false,
                        });
                      }}
                    />
                  )}
                  {this.state.showPaymentErrorNotify && (
                    <PaymentErrorNotify
                      close={() => {
                        this.setState({
                          showPaymentErrorNotify: false,
                        });
                      }}
                    />
                  )}
                  {this.state.showPaymentCancelNotify && (
                    <PaymentCancelNotify
                      close={() => {
                        this.setState({
                          showPaymentCancelNotify: false,
                        });
                      }}
                      backUrl={this.state.paymentCancelNotifyBackUrl}
                    />
                  )}
                </BrowserRouter>
              </ToastProvider>
            </TooltipProvider>
            <Toaster />
          </Provider>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

const PaymentErrorNotify = ({ close }) => {
  return (
    <div className="modal">
      <div className="modal_overlay" onClick={close}></div>
      <div className="modal_window">
        <div className="modal_close" onClick={close}>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
            <line
              y1="-1"
              x2="21.2624"
              y2="-1"
              transform="matrix(0.706015 0.708197 -0.706015 0.708197 1 2.35706)"
              stroke="black"
              strokeWidth="2"
            />
            <line
              y1="-1"
              x2="21.2624"
              y2="-1"
              transform="matrix(-0.706015 0.708197 0.706015 0.708197 16.7895 2.35706)"
              stroke="black"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div className="modal_msg">
          Something went wrong during payment.
          <br />
          Please repeat or write to our technical support.
        </div>
      </div>
    </div>
  );
};
