/* eslint-disable @typescript-eslint/no-explicit-any */
import { Canvas, Image, StaticCanvas, TCrossOrigin } from 'fabric';

/**
 * Calculates scale and position for a background image
 * based on the image scaling algorithm according to container dimensions
 *
 * @param imageWidth Original image width
 * @param imageHeight Original image height
 * @param containerWidth Container (slide) width
 * @param containerHeight Container (slide) height
 * @returns Object with scale and offset coordinates
 */
export const calculateBackgroundImageScaleAndOffset = (
  imageWidth: number,
  imageHeight: number,
  containerWidth: number,
  containerHeight: number,
): { scale: number; x: number; y: number } => {
  // Calculate scale to make the image cover the entire container
  // Python code equivalent: float scale = Math.max(shape.getWidth() / pic.getWidth(), shape.getHeight() / pic.getHeight())
  const scale = Math.max(containerWidth / imageWidth, containerHeight / imageHeight);

  // Calculate the new dimensions of the scaled image
  // Python code equivalent: float imageWidth = pic.getWidth() * scale, float imageHeight = pic.getheight() * scale
  const scaledImageWidth = imageWidth * scale;
  const scaledImageHeight = imageHeight * scale;

  // Calculate the offset for centering the image
  // Python code equivalent: float x = -Math.abs(imageWidth - pic.getWidth()) / 2, float y = -Math.abs(imageHeight - pic.getHeight()) / 2
  const x = -Math.abs(scaledImageWidth - containerWidth) / 2;
  const y = -Math.abs(scaledImageHeight - containerHeight) / 2;

  return { scale, x, y };
};

/**
 * Applies scaling and positioning to a Fabric image object
 *
 * @param imageObject Fabric Image object
 * @param containerWidth Container (slide) width
 * @param containerHeight Container (slide) height
 * @returns Image object with applied settings
 */
export const applyBackgroundImageScaling = (
  imageObject: Image,
  containerWidth: number,
  containerHeight: number,
): Image => {
  // Get the original image dimensions
  const originalWidth = imageObject._originalElement.width;
  const originalHeight = imageObject._originalElement.height;

  // Calculate scale and offset
  const { scale, x, y } = calculateBackgroundImageScaleAndOffset(
    originalWidth,
    originalHeight,
    containerWidth,
    containerHeight,
  );

  // Apply calculated values to the image
  imageObject.scaleX = scale;
  imageObject.scaleY = scale;
  imageObject.left = x;
  imageObject.top = y;

  // Center the transformation point
  imageObject.originX = 'left';
  imageObject.originY = 'top';

  return imageObject;
};

/**
 * Sets an image as the background of a canvas using canvas.backgroundImage
 *
 * @param canvas Fabric Canvas
 * @param imageSrc Image URL
 * @param slideWidth Slide width (if not specified, canvas width is used)
 * @param slideHeight Slide height (if not specified, canvas height is used)
 * @param options Additional parameters
 * @returns Promise with the canvas instance
 */
export const setCanvasBackgroundImage = (
  canvas: Canvas | StaticCanvas,
  imageSrc: string,
  slideWidth?: number,
  slideHeight?: number,
  options: {
    crossOrigin?: TCrossOrigin;
    backgroundColor?: string;
  } = {},
): Promise<Canvas | StaticCanvas> => {
  return new Promise((resolve, reject) => {
    // If slide dimensions are not provided, use canvas dimensions
    const containerWidth = slideWidth || canvas.getWidth();
    const containerHeight = slideHeight || canvas.getHeight();

    // Set background color if provided
    if (options.backgroundColor) {
      canvas.backgroundColor = options.backgroundColor;
    }

    // Load image and set as background
    Image.fromURL(imageSrc, {
      crossOrigin: options.crossOrigin || 'anonymous',
    })
      .then((img) => {
        try {
          // Apply scaling and positioning using the existing function
          applyBackgroundImageScaling(img, containerWidth, containerHeight);

          // Set as background image
          canvas.backgroundImage = img;

          // Render the canvas with the background
          canvas.requestRenderAll();
          resolve(canvas);
        } catch (error) {
          console.error('Error setting background image:', error);
          reject(error);
        }
      })
      .catch((error) => {
        console.error('Error loading background image:', error);
        reject(error);
      });
  });
};
