/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCanvasStore } from '../store';

export class FabricInstanceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FabricInstanceError';
  }
}

export class ThumbnailGenerationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ThumbnailGenerationError';
  }
}

/**
 * Generates a thumbnail for the specified canvas
 * @param canvasId - ID of the canvas to generate thumbnail for
 * @param multiplier - Scale factor for the thumbnail (default: 0.5)
 * @returns Data URL of the generated thumbnail
 */
export const generateThumbnail = (canvasId: string, multiplier = 0.5) => {
  const canvasStore = useCanvasStore.getState();
  const fabricInstance = canvasStore.canvasData[canvasId]?.fabricInstance;

  if (fabricInstance) {
    try {
      // Ensure the canvas is properly rendered before generating thumbnail
      fabricInstance.requestRenderAll();

      // Generate the thumbnail
      const res = fabricInstance.toDataURL({
        format: 'png',
        multiplier,
        // Ensure we capture the entire canvas
        left: 0,
        top: 0,
        width: fabricInstance.getWidth(),
        height: fabricInstance.getHeight(),
      });

      console.debug(
        `Generated thumbnail for canvas ${canvasId} with dimensions ${fabricInstance.getWidth()}x${fabricInstance.getHeight()}`,
      );

      return res;
    } catch (error) {
      console.error('Error generating thumbnail:', error);
      throw new ThumbnailGenerationError(`Error generating thumbnail for canvasId: ${canvasId}`);
    }
  }

  throw new FabricInstanceError(`Fabric instance not found for canvasId: ${canvasId}`);
};
