/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand';

import { prepareHeaders } from '@app/app/lib/api';

import { serverPath } from '@app/utils/server-path';

import { Slide, Stage } from '../types';

type DownloadStatus = 'idle' | 'loading' | 'success' | 'error';

type SlideStoreState = {
  slides: Slide[];
  downloadStatus: DownloadStatus;
  downloadError: string | null;
  palette: string[];
  imageDataSource: string;
  workspaceDescription: string;
};

type SlideStoreActions = {
  setSlides: (slides: Slide[]) => void;
  updateSlideStage: (slideId: string, newStages: Stage[]) => void;
  setConfig: (slideId: string, config: any) => void;
  addEditableSlide: (id: number, position: number) => void;
  addSlide: (id: number, position: number, stages?: Stage[]) => void;
  fetchSlide: (workspaceId: string, slideId: string) => Promise<void>;
  clearStore: () => void;
  removeSlide: (slideId: string) => Slide | null;
  setSlideStageComposeSuccess: (slideId: string) => void;
  setDownloadStatus: (status: DownloadStatus, error?: string | null) => void;
  startDownload: () => void;
  finishDownload: (isSuccess: boolean, error?: string) => void;
  resetDownloadStatus: () => void;
  setPalette: (colors: string[]) => void;
  addColorToPalette: (color: string) => void;
  removeColorFromPalette: (color: string) => void;
  clearPalette: () => void;
  updateColorInPalette: (oldColor: string, newColor: string) => void;
  setImageDataSource: (dataSource: string) => void;
  setWorkspaceDescription: (description: string) => void;
};

type SlideStore = SlideStoreState & SlideStoreActions;

const defaultState: SlideStoreState = {
  slides: [],
  downloadStatus: 'idle',
  downloadError: null,
  palette: [],
  imageDataSource: 'default',
  workspaceDescription: '',
};

export const useSlideStore = create<SlideStore>((set) => ({
  ...defaultState,

  addSlide: (id, position, stages) =>
    set((state) => ({
      slides: [
        ...state.slides.map((slide) =>
          slide.position >= position ? { ...slide, position: slide.position + 1 } : slide,
        ),
        { id, position, stages: stages ? [...stages] : ([] as Stage[]) },
      ].sort((a, b) => a.position - b.position),
    })),

  addEditableSlide: (id, position) =>
    set((state) => ({
      slides: [
        ...state.slides.map((slide) =>
          slide.position >= position ? { ...slide, position: slide.position + 1 } : slide,
        ),
        {
          id,
          position,
          stages: [{ type: 'editableSlide', status: 'success' }] as Stage[],
        },
      ].sort((a, b) => a.position - b.position),
    })),

  setSlides: (slides) => set({ slides }),

  updateSlideStage: (slideId, newStages) =>
    set((state) => {
      const updatedSlides = state.slides.map((slide) =>
        slide.id.toString() === slideId
          ? { ...slide, stages: [...newStages] } //[...slide.stages, ...newStages]
          : slide,
      );
      console.debug('Updated slides:', updatedSlides);
      return { slides: updatedSlides };
    }),

  setSlideStageComposeSuccess: (slideId) => {
    set((state) => {
      const updatedSlides = state.slides.map((slide) =>
        slide.id.toString() === slideId ? { ...slide, stages: [] } : slide,
      );
      console.debug('Updated slides:', updatedSlides);
      return { slides: updatedSlides };
    });
  },

  setConfig: (slideId, config) =>
    set((state) => ({
      slides: state.slides.map((slide) =>
        slide.id.toString() === slideId ? { ...slide, config } : slide,
      ),
    })),

  fetchSlide: async (workspaceId, slideId) => {
    try {
      //TODO: hook tanstack query
      const response = await fetch(
        `${serverPath.workspace.getSlideConfig}${workspaceId}/${slideId}`,
        {
          method: 'GET',
          headers: prepareHeaders(),
        },
      );
      const config = await response.json();

      set((state) => ({
        slides: state.slides.map((slide) =>
          slide.id.toString() === slideId.toString()
            ? {
                ...slide,
                config: config.result,
                stages: [{ type: 'compose', status: 'success' }],
              }
            : slide,
        ),
      }));
    } catch (error) {
      set((state) => ({
        slides: state.slides.map((slide) =>
          slide.id.toString() === slideId
            ? {
                ...slide,
                stages: [{ type: 'editableSlide', status: 'success' }], // Add editable slide stage if config is not loaded
              }
            : slide,
        ),
      }));
      console.error(`Error load config for ${slideId}:`, error);
    }
  },

  removeSlide: (slideId: string): Slide | null => {
    let closestSlide: Slide | null = null;

    set((state) => {
      const slideIndex = state.slides.findIndex((slide) => slide.id.toString() === slideId);
      if (slideIndex === -1) return { slides: state.slides }; // If the slide is not found, do nothing

      const updatedSlides = state.slides
        .filter((slide) => slide.id.toString() !== slideId) // Remove the slide
        .sort((a, b) => a.position - b.position) // Sort by position
        .map((slide, index) => ({ ...slide, position: index })); // Reset positions

      // Find the closest slide to the deleted one
      closestSlide =
        updatedSlides[slideIndex - 1] || // Previous slide (it has shifted)
        updatedSlides[slideIndex] || // Next slide (it has shifted)
        null; // If there is no slide

      return { slides: updatedSlides };
    });

    return closestSlide;
  },

  setDownloadStatus: (status, error = null) =>
    set({ downloadStatus: status, downloadError: error }),

  startDownload: () => set({ downloadStatus: 'loading', downloadError: null }),

  finishDownload: (isSuccess, error = '') =>
    set({
      downloadStatus: isSuccess ? 'success' : 'error',
      downloadError: isSuccess ? null : error,
    }),

  resetDownloadStatus: () => set({ downloadStatus: 'idle', downloadError: null }),

  clearStore: () => set({ ...defaultState }),

  setPalette: (colors) => set({ palette: colors }),

  addColorToPalette: (color) =>
    set((state) => {
      if (!state.palette.includes(color)) {
        return { palette: [...state.palette, color] };
      }
      return state;
    }),

  removeColorFromPalette: (color) =>
    set((state) => ({
      palette: state.palette.filter((c) => c !== color),
    })),

  clearPalette: () => set({ palette: [] }),

  updateColorInPalette: (oldColor, newColor) =>
    set((state) => ({
      palette: state.palette.map((color) => (color === oldColor ? newColor : color)),
    })),

  setImageDataSource: (dataSource) => set({ imageDataSource: dataSource }),

  setWorkspaceDescription: (workspaceDescription: string) => set({ workspaceDescription }),
}));
