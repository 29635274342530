import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['stage-header-styles-cvaRoot', 'pt-2 px-5'], {
  variants: {
    isWorkspaceVariant: {
      true: 'min-w-[984px]',
    },
  },
  defaultVariants: {
    isWorkspaceVariant: false,
  },
});

export const cvaContent = cva(['stage-header-styles-cvaContent', 'flex items-center']);

export const cvaNavigation = cva(['stage-header-styles-cvaNavigation', 'flex items-center gap-2']);

export const cvaArrowIcon = cva([
  'stage-header-styles-cvaArrowIcon',
  'h-3 w-3 fill-general_MI_800_disabled',
]);

export const cvaNavigationItem = cva(
  ['stage-header-styles-cvaNavigationItem', 'text-font_ds_title_body_body_14 pointer-events-auto'],
  {
    variants: {
      isActive: {
        true: 'text-general_MI_500_default cursor-default',
        false: 'text-general_text_MII_disabled cursor-pointer',
      },
    },
    defaultVariants: {
      isActive: false,
    },
  },
);

export const cvaActionsButtons = cva([
  'stage-header-styles-cvaActionsButton',
  'flex gap-x-7 ml-auto mr-7 semiMd-max:hidden',
]);

export const cvaBurgerButton = cva(['header-styles-cvaBurgerButton', 'ml-auto']);

export const cvaPresentationName = cva([
  'header-styles-cvaPresentationName',
  'relative z-[100] text-general_text_MII_secondary_ii text-font_ds_title_body_black_20 mb-1',
]);
