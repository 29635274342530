import { BASE_CANVAS_DIMENSIONS, MAX_CANVAS_DIMENSIONS } from '../config/constants';

export const getCanvasScale = (containerWidth: number, containerHeight: number) => {
  const scaleWidth = containerWidth / BASE_CANVAS_DIMENSIONS.width;
  const scaleHeight = containerHeight / BASE_CANVAS_DIMENSIONS.height;
  let scale = Math.min(scaleWidth, scaleHeight);
  scale = Math.min(scale, MAX_CANVAS_DIMENSIONS.width / BASE_CANVAS_DIMENSIONS.width);
  scale = Math.min(scale, MAX_CANVAS_DIMENSIONS.height / BASE_CANVAS_DIMENSIONS.height);
  return scale;
};
