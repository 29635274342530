/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group, Image } from 'fabric';

import { MenuItemWithBackup } from '../types/types';

import { generateObjectId } from './generateId';

import { scaleImage } from './imageUtils';

export const changeIconForBullet = async ({
  canvas,
  canvasId,
  targetIds, // Array of possible groupIds
  newIconSrc: src,
  sourceExt,
  addBackup,
  cbOnError,
}: Omit<MenuItemWithBackup, 'closeOnClick' | 'item'> & {
  newIconSrc: string;
  sourceExt: string;
  cbOnError?: () => void;
}) => {
  // For each groupId from targetIds
  for (const groupId of targetIds) {
    // Find group by groupId
    const activeObject = canvas.getObjects().find((obj) => obj.id === groupId);

    if (activeObject && activeObject instanceof Group) {
      // Find "bullet-image-item" object that needs to be replaced
      const bulletImageItem = activeObject
        .getObjects()
        .find((obj) => obj.category === 'bullet-image-item');

      if (bulletImageItem) {
        const targetWidth = bulletImageItem.width * bulletImageItem.scaleX;
        const targetHeight = bulletImageItem.height * bulletImageItem.scaleY;

        const maxSize = Math.max(targetWidth, targetHeight);

        const imageColor = bulletImageItem.imageColor;

        // Remove old image
        activeObject.remove(bulletImageItem);

        // If the link contains {color} - need to substitute the bullet icon color that we are replacing
        if (src.includes('{color}')) {
          src = src.replace('{color}', imageColor ?? '');
        }
        try {
          // Load new image
          const img = await Image.fromURL(src, {
            crossOrigin: 'anonymous',
          });

          const imgToPasteWidth = img._originalElement.width;
          const imgToPasteHeight = img._originalElement.height;

          const scaleImgToPaste = Math.min(maxSize / imgToPasteWidth, maxSize / imgToPasteHeight);

          // Scale new image
          scaleImage(img, imgToPasteWidth * scaleImgToPaste, imgToPasteHeight * scaleImgToPaste);

          // Set parameters for new image, preserving parameters from deleted image
          img.set({
            name: bulletImageItem.name,
            left: bulletImageItem.left,
            top: bulletImageItem.top,
            category: bulletImageItem.category,
            idGroup: bulletImageItem.idGroup,
            id: generateObjectId(canvasId),
            originX: bulletImageItem.originX,
            originY: bulletImageItem.originY,
            noCopy: bulletImageItem.noCopy,
            available_images: bulletImageItem.available_images,
            source_ext: sourceExt,
            imageColor: bulletImageItem.imageColor,
            media_type: bulletImageItem.media_type,
            inner_id: bulletImageItem.inner_id,
            zIndex: bulletImageItem.zIndex,
          });

          // Add new image to group
          activeObject.add(img);

          // Update group
          activeObject.setCoords();
        } catch (error) {
          console.error('Error while changing icon for bullet:', error);
          cbOnError?.();
        }
      }
    }
  }

  // Save backup after changing all groups
  addBackup(canvasId, canvas.toJSON());
  // Redraw canvas
  canvas.renderAll();
};
