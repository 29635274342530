/* eslint-disable @typescript-eslint/no-explicit-any */
import { Object as FabricObject, Group } from 'fabric';

/**
 * Находит объект по ID в группе или на канвасе.
 * @param group Группа, в которой ищем объект.
 * @param currentId ID объекта, который нужно найти.
 * @returns Найденный объект или undefined.
 */
export const findObjectInGroup = (group: Group, currentId: string): FabricObject | undefined => {
  return group._objects.find((obj) => obj.id === currentId);
};

/**
 * Выполняет действие над объектом, если он найден в группе.
 * @param group Группа, в которой ищем объект.
 * @param currentId ID объекта.
 * @param action Действие, которое нужно выполнить с объектом.
 */
export const performActionOnObjectInGroup = (
  group: Group,
  currentId: string,
  action: (obj: FabricObject) => void,
) => {
  const obj = findObjectInGroup(group, currentId);
  if (obj) {
    action(obj);
  }
};
