import { prepareHeaders } from '@app/app/lib/api';
import { serverPath } from '@app/utils/server-path';

import type { ApiResponse } from '../../types/api';

type UpdateWorkspaceParams = {
  workspaceId: string;
  description: string;
};

type UpdateWorkspaceResponse = {
  success: boolean;
};

/**
 * Updates workspace information (name/description)
 * @param workspaceId - ID of the workspace
 * @param description - New description/name of the workspace
 * @returns Promise with the update result
 */
export const updateWorkspace = async ({
  workspaceId,
  description,
}: UpdateWorkspaceParams): Promise<ApiResponse<UpdateWorkspaceResponse>> => {
  const formData = new FormData();
  formData.append('description', description);

  const response = await fetch(`${serverPath.workspace.update}${workspaceId}/`, {
    method: 'POST',
    headers: prepareHeaders(null, true),
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`Error while updating workspace: ${response.statusText}`);
  }

  const data: ApiResponse<UpdateWorkspaceResponse> = await response.json();

  return data;
};
