import { useMutation } from '@tanstack/react-query';

import { ConfiguredToaster, ToastTitle } from '@app/shared/ui/sonner';

import { updateWorkspace } from '../queries/updateWorkspace';
import { useSlideStore } from '../../store';

/**
 * Hook for updating workspace description
 * @returns Mutation for updating workspace description
 */
export const useUpdateWorkspace = () => {
  const { setWorkspaceDescription } = useSlideStore();

  const mutation = useMutation({
    mutationFn: updateWorkspace,
    onSuccess: (data, { description }) => {
      if (data.status) {
        setWorkspaceDescription(description);
        ConfiguredToaster.toastSuccess(<ToastTitle>Workspace description updated</ToastTitle>);
      } else {
        ConfiguredToaster.toastError(
          <ToastTitle>Failed to update workspace description</ToastTitle>,
        );
      }
    },
    onError: (error) => {
      console.error('Error while updating workspace description:', error);
      ConfiguredToaster.toastError(
        <ToastTitle>Error while updating workspace description</ToastTitle>,
      );
    },
  });

  return mutation;
};
