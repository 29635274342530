import { route_path } from '@app/utils/route_path';

import t from '../../../../lib/lng';

export const navLinks = [
  { id: 1, to: route_path.features, title: t.Features },
  { id: 2, to: route_path.pricing, title: t.Pricing },
  { id: 3, to: route_path.about, title: t.AboutUs },
];

export const navLinksV2 = [
  { id: 1, to: route_path.features, title: t.Features },
  { id: 3, to: route_path.about, title: t.AboutUs },
];
