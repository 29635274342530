import { Dispatch, FC, SetStateAction, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useLazyCancelStripeSubscriptionQuery } from '@app/store/api/payments.api';
import { UserData } from '@app/types/user.type';
import { Dialog, DialogContent, DialogTitle } from '@app/shared/ui/dialog';
import { Button } from '@app/shared/ui/button';
import { useHeaderStore } from '@app/widgets/header/model/header.store';

import t from '../../../../lib/lng';

import {
  cvaButton,
  cvaButtons,
  cvaContainer,
  cvaContent,
  cvaContentRow,
  cvaContentWithGap,
  cvaText,
  cvaTextDate,
  cvaTitle,
} from './payments-dialog-styles';

type PaymentsProps = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  product_balance: UserData['product_balance'];
  subscriptionActive: boolean;
};

type DialogStep = 'main' | 'confirmation';

const modalTitle: Record<DialogStep, string> = {
  main: t.Payments,
  confirmation: t.doYouWantToCancelSubscription,
};

export const PaymentsDialog: FC<PaymentsProps> = (props) => {
  const { isOpen, setOpen, product_balance, subscriptionActive } = props;

  const [step, setStep] = useState<DialogStep>('main');

  const [cancelStripeSubscription] = useLazyCancelStripeSubscriptionQuery();

  const { toggleHeaderMenuOpen } = useHeaderStore();
  const closeMobileMenu = () => toggleHeaderMenuOpen(false);

  const handleCloseAll = () => {
    setOpen(false);
    closeMobileMenu();
  };

  const handleConfirmCancellation = () => {
    cancelStripeSubscription(null);
    // TODO before here was "transaction_cancel_subscription" event
    setStep('main');
  };

  const handleBackToMain = () => setStep('main');
  const handleToConfirm = () => setStep('confirmation');

  return (
    <Dialog open={isOpen} onOpenChange={(open) => setOpen(open)}>
      <DialogContent className="max-w-[50rem]">
        <div className={cvaContainer()}>
          <DialogTitle asChild>
            <h3 className={cvaTitle()}>{modalTitle[step]}</h3>
          </DialogTitle>

          <div className={cvaContent()}>
            {step === 'main' && (
              <>
                {product_balance?.product?.is_trial && (
                  <div className={cvaContentWithGap()}>
                    <div className={cvaContentRow()}>
                      <div className={cvaText()}>
                        {product_balance?.expire && (
                          <>
                            <span>{t.trialWillCloseOn} </span>
                            <span className={cvaTextDate()}>
                              {new Date(product_balance?.expire).toLocaleDateString('en-US')}
                            </span>
                          </>
                        )}
                      </div>
                      {product_balance.expire && (
                        <Button borderRadius="3xl" size="large" onClick={handleCloseAll} asChild>
                          <NavLink to="/pricing/">{t.upgradeSubscription}</NavLink>
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {!product_balance?.product?.is_trial && subscriptionActive && (
                  <div className={cvaContentWithGap()}>
                    <div className={cvaText()}>
                      <span>{product_balance?.product.name} </span>
                      {product_balance.expire && (
                        <>
                          <span>{t.paidUntil} </span>
                          <span className={cvaTextDate()}>
                            {new Date(product_balance.expire).toLocaleDateString('en-US')}
                          </span>
                        </>
                      )}
                    </div>
                    {product_balance.expire && (
                      <div className={cvaButtons()}>
                        <Button variant="outline" size="large" onClick={handleToConfirm}>
                          {t.cancelSubscription}
                        </Button>

                        <Button
                          size="large"
                          onClick={handleCloseAll}
                          isFullWidth
                          className={cvaButton()}
                        >
                          {t.Ok}
                        </Button>
                      </div>
                    )}
                  </div>
                )}

                {!product_balance?.product?.is_trial && !subscriptionActive && (
                  <div className={cvaContentWithGap()}>
                    <div className={cvaContentRow()}>
                      <div className={cvaText()}>{t.noSubscription}</div>

                      <Button borderRadius="3xl" size="large" onClick={handleCloseAll} asChild>
                        <NavLink to="/pricing/">{t.upgradeSubscription}</NavLink>
                      </Button>
                    </div>

                    {product_balance.expire && (
                      <div className={cvaText()}>
                        <span>{t.serviceWillBeFullyOperational} </span>
                        <span className={cvaTextDate()}>
                          {new Date(product_balance.expire).toLocaleDateString('en-US')}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}

            {step === 'confirmation' && (
              <>
                <p className={cvaText()}>
                  {t.formatString(t.ifYouCancelSubscription, <br />)}{' '}
                  {product_balance?.expire &&
                    new Date(product_balance.expire).toLocaleDateString('en-US')}
                </p>

                <div className={cvaButtons({ withMargin: true })}>
                  <Button
                    variant="outline"
                    size="large"
                    isFullWidth
                    onClick={handleConfirmCancellation}
                    className={cvaButton()}
                  >
                    {t.Yes}
                  </Button>

                  <Button
                    size="large"
                    onClick={handleBackToMain}
                    isFullWidth
                    className={cvaButton()}
                  >
                    {t.No}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
