/* eslint-disable @typescript-eslint/no-explicit-any */
import { Group } from 'fabric';

import { MenuItemWithBackup } from '../types/types';

import { performActionOnObjectInGroup } from './groupUtils';

/**
 * Locks or unlocks an object on the canvas
 * @param params Parameters for locking/unlocking the object
 * @param isLocked Lock flag (true - lock, false - unlock)
 */
export const toggleObjectLock = (
  { targetIds, canvas, canvasId, addBackup }: MenuItemWithBackup,
  isLocked: boolean,
) => {
  const idsToLock = Array.isArray(targetIds) ? targetIds : [targetIds];

  idsToLock.forEach((currentId) => {
    const targetObject = canvas.getObjects().find((object) => {
      if (object.id === currentId) {
        return true;
      } else if (object.category) {
        // If the object is in a group (bullets)
        if (object.category.toLowerCase() === 'bullet' && object instanceof Group) {
          performActionOnObjectInGroup(object, currentId, (obj) => {
            applyLockToObject(obj, isLocked);
          });
          return; // Object found and processed
        }
      }
      return false;
    });

    if (targetObject) {
      applyLockToObject(targetObject, isLocked);
    }
  });

  // Create a backup after changes
  const cfg = canvas.toJSON();
  addBackup(canvasId, cfg);
  canvas.requestRenderAll();
};

/**
 * Applies lock settings to an object
 * @param obj Object to lock
 * @param isLocked Lock flag (true - lock, false - unlock)
 *
 * This function manages the lock state of an object by saving its original state
 * in the `originalLockState` property if it is not already set. When locking the object,
 * it saves the current state if `originalLockState` is empty and applies lock settings.
 * When unlocking, it restores the state from `originalLockState` if available.
 * If `originalLockState` is empty during unlocking, it saves the current state before
 * applying the unlock settings.
 */
export const applyLockToObject = (obj: any, isLocked: boolean) => {
  // Initialize originalLockState if it doesn't exist
  if (!obj.originalLockState) {
    obj.originalLockState = {};
  }

  if (isLocked) {
    // Save current state if originalLockState is empty
    if (Object.keys(obj.originalLockState).length === 0) {
      obj.originalLockState = {
        lockMovementX: obj.lockMovementX,
        lockMovementY: obj.lockMovementY,
        lockScalingX: obj.lockScalingX,
        lockScalingY: obj.lockScalingY,
        lockRotation: obj.lockRotation,
        selectable: obj.selectable,
        evented: obj.evented,
        hasControls: obj.hasControls,
      };
    }

    // Apply lock settings
    obj.set({
      selectable: true,
      evented: true,
      lockMovementX: true,
      lockMovementY: true,
      lockScalingX: true,
      lockScalingY: true,
      lockRotation: true,
      hasControls: false,
    });
  } else {
    // Restore original state if available
    if (Object.keys(obj.originalLockState).length > 0) {
      obj.set({
        selectable: obj.originalLockState.selectable,
        evented: obj.originalLockState.evented,
        lockMovementX: obj.originalLockState.lockMovementX,
        lockMovementY: obj.originalLockState.lockMovementY,
        lockScalingX: obj.originalLockState.lockScalingX,
        lockScalingY: obj.originalLockState.lockScalingY,
        lockRotation: obj.originalLockState.lockRotation,
        hasControls: obj.originalLockState.hasControls,
      });
    } else {
      // If originalLockState is empty, save current state
      obj.originalLockState = {
        lockMovementX: obj.lockMovementX,
        lockMovementY: obj.lockMovementY,
        lockScalingX: obj.lockScalingX,
        lockScalingY: obj.lockScalingY,
        lockRotation: obj.lockRotation,
        selectable: obj.selectable,
        evented: obj.evented,
        hasControls: obj.hasControls,
      };
    }
  }

  // Update lock state
  obj.isLocked = isLocked;
};

/**
 * Checks if an object is locked
 * @param obj Object to check
 * @returns true if the object is locked
 */
export const isObjectLocked = (obj: any): boolean => {
  return obj?.isLocked === true;
};
