// TODO: Move to context-menu slice
import { Canvas } from 'fabric';

import { OFFSET_Y_MENU } from '../config/constants';

import { MenuItem, MenuType } from '../types/types';

import { isObjectLocked } from './lockObjects';
import { findObjectById } from './findObjects';

export const calculatePositionMenu = (
  origin: number | string | undefined,
  value: number,
  dimension: number,
  scale: number,
  offset = 0,
  objectScale = 1,
) => {
  const scaledDimension = dimension * objectScale;

  if (origin === 'center') {
    // For objects with a central point of attachment
    return value * scale - (scaledDimension * scale) / 2 - offset;
  } else if (origin === 'left' || origin === 'top') {
    // For objects with a left/top attachment
    return value * scale - offset;
  } else if (origin === 'right' || origin === 'bottom') {
    // For objects with a right/bottom attachment
    return (value - scaledDimension) * scale - offset;
  }

  // By default, consider as center
  return value * scale - (scaledDimension * scale) / 2 - offset;
};

/**
 * Generates menu items for a specific object type.
 * @param params Parameters for generating menu items.
 * @param params.objectType The type of the object ('textbox', 'group', 'rectangle', etc.).
 * @param params.targetIds The IDs of the target objects.
 * @param params.canvas The Canvas instance.
 * @param params.canvasId The Canvas identifier.
 * @returns A list of menu items.
 */
export const getMenuItemsByObjectType = ({
  objectType,
  targetIds,
  canvas,
  canvasId,
  isDevMode = false,
}: {
  objectType: string; // The type of the object
  targetIds: string[]; // The IDs of the target objects
  canvas: Canvas; // The Canvas instance
  canvasId: string; // The Canvas identifier
  isDevMode?: boolean;
}): MenuItem[] => {
  let typesMenu: MenuType[] = []; // A list of menu types

  // Найдите объект по его ID
  const activeObject =
    targetIds.length === 1 ? findObjectById(canvas.getObjects(), targetIds[0]) : null;

  // Проверьте, заблокирован ли объект
  const isLocked = activeObject ? isObjectLocked(activeObject) : false;

  // Если объект заблокирован, показываем только опцию разблокировки
  if (isLocked) {
    typesMenu = ['lockObject']; // Только опция разблокировки
    return typesMenu.map((type) => ({
      item: { type },
      targetIds,
      canvas,
      canvasId,
    }));
  }

  if (isDevMode) {
    typesMenu = typesMenu.concat(['devMode']);
  }

  // Determine menu items based on the object type
  switch (objectType) {
    case 'textbox':
      typesMenu = typesMenu.concat([
        'textAlign',
        'fontStyle',
        'fontSize',
        'deleteAndCopy',
        'lockObject',
      ]);

      break;
    case 'bullet-group':
      typesMenu = typesMenu.concat(['editBullet', 'deleteAndCopy', 'lockObject']);
      break;
    case 'bullet-textbox':
      typesMenu = typesMenu.concat([
        'textAlign',
        'fontStyle',
        'fontSize',
        'editBullet',
        'delete',
        'lockObject',
      ]);
      break;
    case 'group':
      typesMenu = typesMenu.concat(['align', 'deleteAndCopy', 'lockObject']);
      break;
    case 'group-text':
      typesMenu = typesMenu.concat([
        'align',
        'textAlign',
        'fontStyle',
        'fontSize',
        'deleteAndCopy',
        'lockObject',
      ]);
      break;
    case 'group-bullets':
      typesMenu = typesMenu.concat(['align', 'editBullet', 'deleteAndCopy', 'lockObject']);
      break;
    case 'rectangle':
    case 'triangle':
    case 'circle':
      typesMenu = typesMenu.concat(['fillColor', 'deleteAndCopy', 'lockObject']);
      break;
    case 'imageCrop':
      typesMenu = typesMenu.concat(['editImageCrop', 'deleteAndCopy', 'lockObject']);
      break;
    case 'image':
      typesMenu = typesMenu.concat(['changeImage', 'deleteAndCopy', 'lockObject']);
      break;
    case 'path':
      typesMenu = typesMenu.concat(['fillColor', 'deleteAndCopy', 'lockObject']);
      break;
    default:
      typesMenu = typesMenu.concat(['deleteAndCopy', 'lockObject']);
      break;
  }

  return typesMenu.map((type) => ({
    item: { type },
    targetIds,
    canvas,
    canvasId,
  }));
};

type UpdateContextMenuParams = {
  canvas: Canvas;
  canvasId: string;
  setMenuItems: (items: MenuItem[]) => void;
  setContextMenuPos: (pos: { x: number; y: number }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buffer: { objects: any[] };
  resetMenu: () => void;
  isDevMode?: boolean;
};

export const updateContextMenuUtility = ({
  canvas,
  canvasId,
  setMenuItems,
  setContextMenuPos,
  buffer,
  resetMenu,
  isDevMode = false,
}: UpdateContextMenuParams) => {
  setMenuItems([]);
  const activeObjects = canvas.getActiveObjects();
  const zoom = canvas.getZoom();

  // console.debug('zoom', zoom);

  if (activeObjects && activeObjects.length > 0) {
    const activeGroupSelection = canvas.getActiveObject();
    if (!activeGroupSelection) {
      return;
    }

    const isTextInBullet =
      activeGroupSelection.type.toLowerCase() === 'textbox' &&
      activeGroupSelection.category?.toLowerCase() === 'bullet-text-item';

    const isGroupBullet =
      activeGroupSelection.type.toLowerCase() === 'group' &&
      activeGroupSelection?.category?.toLowerCase() === 'bullet';

    const isIncludeTextsObjects = activeObjects.every(
      (obj) => obj.type.toLowerCase() === 'textbox',
    );

    const isGroupBullets = activeObjects.every((obj) => obj.category?.toLowerCase() === 'bullet');

    // Determine target object for positioning
    const targetObject = isTextInBullet
      ? canvas.getObjects().find((obj) => obj.id === activeGroupSelection.idGroup) ||
        activeGroupSelection
      : activeGroupSelection;

    // Calc x
    const x = calculatePositionMenu(
      targetObject.originX || 'center',
      targetObject.left,
      targetObject.width,
      zoom,
      0,
      targetObject.scaleX || 1,
    );

    // Calc y
    const y = calculatePositionMenu(
      targetObject.originY || 'center',
      targetObject.top,
      targetObject.height,
      zoom,
      OFFSET_Y_MENU,
      targetObject.scaleY || 1,
    );

    if (activeObjects.length > 1) {
      if (isGroupBullets) {
        setMenuItems(
          getMenuItemsByObjectType({
            objectType: 'group-bullets',
            targetIds: activeObjects.map((item) => item.id),
            canvas,
            canvasId,
            isDevMode,
          }),
        );
      } else if (isIncludeTextsObjects) {
        setMenuItems(
          getMenuItemsByObjectType({
            objectType: 'group-text',
            targetIds: activeObjects.map((item) => item.id),
            canvas,
            canvasId,
            isDevMode,
          }),
        );
      } else {
        setMenuItems(
          getMenuItemsByObjectType({
            objectType: 'group',
            targetIds: activeObjects.map((item) => item.id),
            canvas,
            canvasId,
            isDevMode,
          }),
        );
      }
    } else {
      const activeObject = activeObjects[0]; //Chosen 1 element
      switch (activeObject.type?.toLocaleLowerCase()) {
        case 'textbox':
          if (activeObject.category?.toLocaleLowerCase() === 'bullet-text-item') {
            setMenuItems(
              getMenuItemsByObjectType({
                objectType: 'bullet-textbox',
                targetIds: [activeObject.id],
                canvas,
                canvasId,
                isDevMode,
              }),
            );
          } else {
            setMenuItems(
              getMenuItemsByObjectType({
                objectType: 'textbox',
                targetIds: [activeObject.id],
                canvas,
                canvasId,
                isDevMode,
              }),
            );
          }
          break;
        case 'circle':
        case 'rectangle':
          setMenuItems(
            getMenuItemsByObjectType({
              objectType: activeObject.type,
              targetIds: [activeObject.id],
              canvas,
              canvasId,
              isDevMode,
            }),
          );
          break;
        case 'image':
          if (activeObject.category?.toLocaleLowerCase() === 'crop') {
            setMenuItems(
              getMenuItemsByObjectType({
                objectType: 'imageCrop',
                targetIds: [activeObject.id],
                canvas,
                canvasId,
                isDevMode,
              }),
            );
          } else if (
            activeObject?.media_type?.toLowerCase() === 'photo' ||
            activeObject?.media_type?.toLowerCase() === 'object'
          ) {
            setMenuItems(
              getMenuItemsByObjectType({
                objectType: 'image',
                targetIds: [activeObject.id],
                canvas,
                canvasId,
                isDevMode,
              }),
            );
          } else {
            setMenuItems(
              getMenuItemsByObjectType({
                objectType: 'unknown',
                targetIds: [activeObject.id],
                canvas,
                canvasId,
                isDevMode,
              }),
            );
          }
          break;
        case 'group':
          if (isGroupBullet) {
            setMenuItems(
              getMenuItemsByObjectType({
                objectType: 'bullet-group',
                targetIds: [activeObject.id],
                canvas,
                canvasId,
                isDevMode,
              }),
            );
          }
          break;
        case 'path':
          setMenuItems(
            getMenuItemsByObjectType({
              objectType: 'path',
              targetIds: [activeObject.id],
              canvas,
              canvasId,
              isDevMode,
            }),
          );
          break;
        default:
          // Для объектов с неопределенным типом все равно показываем меню с deleteAndCopy
          setMenuItems(
            getMenuItemsByObjectType({
              objectType: 'unknown',
              targetIds: [activeObject.id],
              canvas,
              canvasId,
              isDevMode,
            }),
          );
          break;
      }
    }
    if (x && y) {
      setContextMenuPos({ x, y });
    }
  } else {
    if (buffer.objects.length) {
      setMenuItems([
        {
          item: { type: 'paste' },
          targetIds: [],
          canvas,
          canvasId,
          closeOnClick: true,
        },
      ]);
    } else {
      resetMenu();
    }
  }
};
