import { create } from 'zustand';

type HeaderState = {
  isMenuOpen: boolean;
};

type HeaderAction = {
  toggleHeaderMenuOpen: (isOpen: HeaderState['isMenuOpen']) => void;
};

const defaultState: HeaderState = {
  isMenuOpen: false,
};

export const useHeaderStore = create<HeaderState & HeaderAction>((set) => ({
  ...defaultState,
  toggleHeaderMenuOpen: (isOpen) => set({ isMenuOpen: isOpen }),
}));
