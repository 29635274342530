import { FC, MouseEvent } from 'react';
import { Link, NavLink, useOutletContext } from 'react-router-dom';

import type { CommonOutletContext } from '@app/pages/common.outlet';
import { Button } from '@app/shared/ui/button';
import { cvaIcon } from '@app/shared/ui/elements';
import { BurgerButton } from '@app/shared/ui/burger-button';
import { useHeaderStore } from '@app/widgets/header/model/header.store';
import { useCannyLink } from '@app/hooks/use-canny-link';
import {
  CogIcon,
  CreditCardIcon,
  CrownIcon,
  LogoutIcon,
  UserCircleIcon,
} from '@app/components/ui/icons';
import { CANNY_URL } from '@app/utils/constants';
import { useLogOutMutation } from '@app/store/api/user.api';
import { route_path } from '@app/utils/route_path';
import { cn } from '@app/app/lib';

import { navLinks, navLinksV2 } from '../../model/static-data';

import { cvaButton, cvaProfileEmail, cvaSeparator } from './header-popover-styles';
import { HeaderPopover } from './header-popover';

export const StageHeaderPopover: FC = () => {
  const { context, user_ctx, setShowPayments, setShowSettings, clearUserCtx, commonContext } =
    useOutletContext<CommonOutletContext>();

  const product_balance = context?.product_balance;

  const { toggleHeaderMenuOpen } = useHeaderStore();

  const closeHeaderMenu = () => toggleHeaderMenuOpen(false);

  const cannyLink = useCannyLink(user_ctx);

  const [logoutQuery, { isLoading: isLoadingLogoutMutation }] = useLogOutMutation();

  const logOutHandler = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logoutQuery().then(() => {
      closeHeaderMenu();
      clearUserCtx();
      // navigate(route_path.main);
      // window.location.reload();
      window.location.href = route_path.main; //Need reload page after logout
    });
  };

  return (
    <HeaderPopover Trigger={<BurgerButton className="semiMd-max:ml-auto" />}>
      {/* Email */}
      {user_ctx?.username && (
        <div className={cvaProfileEmail()}>
          {product_balance?.is_active &&
          !product_balance?.product.is_trial &&
          product_balance.type === 'subscription' ? (
            <CrownIcon className={cn(cvaIcon(), 'text-secondary_warning_SI_400_warning')} />
          ) : (
            <UserCircleIcon className={cvaIcon()} />
          )}

          <span className="truncate">{user_ctx.username}</span>
        </div>
      )}

      <Button
        variant="secondary"
        size="text16"
        onClick={() => setShowPayments(true)}
        className={cvaButton()}
        disabled={isLoadingLogoutMutation}
      >
        <CreditCardIcon className={cvaIcon()} />
        <span>Payments</span>
      </Button>

      <Button
        variant="secondary"
        size="text16"
        onClick={() => setShowSettings(true)}
        className={cvaButton()}
        disabled={isLoadingLogoutMutation}
      >
        <CogIcon className={cvaIcon()} />
        <span>Settings</span>
      </Button>

      <hr className={cvaSeparator()} />

      {(commonContext?.boxed ? navLinksV2 : navLinks).map(({ to, title, id }) => (
        <Button
          key={id}
          variant="secondary"
          size="text16"
          className={cvaButton({ isLink: true })}
          asChild
        >
          <NavLink to={to}>{title}</NavLink>
        </Button>
      ))}

      {!commonContext?.boxed && (
        <>
          <Button variant="secondary" size="text16" className={cvaButton({ isLink: true })} asChild>
            <a href={route_path.blog}>Blog</a>
          </Button>

          <Button variant="secondary" size="text16" className={cvaButton({ isLink: true })} asChild>
            <Link
              data-canny-link="header"
              onClick={cannyLink}
              to={CANNY_URL}
              target="_blank"
              rel="noreferrer"
            >
              Suggest idea
            </Link>
          </Button>
        </>
      )}

      <hr className={cvaSeparator()} />

      <Button
        variant="secondary"
        size="text16"
        onClick={logOutHandler}
        className={cvaButton()}
        disabled={isLoadingLogoutMutation}
      >
        <LogoutIcon className={cvaIcon()} />
        <span>Log out</span>
      </Button>
    </HeaderPopover>
  );
};
