import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { redirect, useNavigate, useOutletContext } from 'react-router';
import { useForm } from 'react-hook-form';

import { useLazyGetUserInitQuery, useLoginMutation } from '@app/store/api/user.api';
import { route_path } from '@app/utils/route_path';
import t from '@lib/lng';

import { analytics } from '@app/app/analytics';

import { CustomerCategory } from '@app/app/analytics/types';

import { cn } from '@app/app/lib/cn';

import { cookie } from '@app/app/lib/cookie';

import { useAppDispatch } from '@app/hooks/use-store';
import { loginSuccess } from '@app/store/slice/auth.slice';

import { CommonOutletContext } from '../common.outlet';

interface LoginResponseWithWelcome {
  status: boolean;
  errors?:
    | {
        __all__?: string[];
        [key: string]: unknown;
      }
    | string
    | null;
  welcome_message?: string;
  welcome_button?: string;
}

const LNG = {
  welcome: 'Welcome back!',
  see_you: 'Glad to See You Again',
  header_desc: 'Your workspace is ready for you',
  errors: {
    required: 'Field is required',
  },
};

type LoginFields = {
  username: string;
  password: string;
};

const LoginPage = () => {
  const { context, commonContext } = useOutletContext<CommonOutletContext>();
  const [authType, setAuthType] = useState(
    commonContext?.social_auth_available ? 'google' : 'email',
  );
  const [showPassword, setShowPassword] = useState(false);
  const [getUserInit, { isLoading: isUserInitLoading }] = useLazyGetUserInitQuery();
  const [loginMutation, { isLoading: isLoginLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<LoginFields>({});

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof context !== 'undefined') redirect(route_path.main);
  }, [context]);

  function submitHandler(data: LoginFields) {
    const formData = new FormData();
    Object.entries(data).forEach(([field, value]) => {
      formData.append(field, value);
    });
    loginMutation({ body: formData })
      .unwrap()
      .then((resultOfLogin: LoginResponseWithWelcome) => {
        if (resultOfLogin.status) {
          dispatch(loginSuccess());
          // Set login flag in session storage
          window.sessionStorage.setItem('login', '1');

          // Check if there's a welcome_message in the response
          if (
            resultOfLogin.welcome_message &&
            localStorage.getItem('hasSeenWelcomeMessage') !== 'true'
          ) {
            // Save welcome message to localStorage for common.outlet handling
            localStorage.setItem('pendingWelcomeMessage', resultOfLogin.welcome_message);
          }
          if (resultOfLogin.welcome_button) {
            localStorage.setItem('pendingWelcomeMessageButtonText', resultOfLogin.welcome_button);
          }

          //FIXME: should be moved to common.outlet level
          getUserInit(null)
            .unwrap()
            .then((userData) => {
              if (userData?.result) {
                const context = userData.result;
                let customer_category = '';
                if (
                  context.product_balance &&
                  context.product_balance.product &&
                  !context.product_balance.product?.is_trial
                ) {
                  if (context.product_balance.is_active) {
                    if (context.product_balance.product.count) customer_category = 'PAYG_client';
                    else customer_category = 'subscriber';
                  } else {
                    if (context.product_balance.product.count) customer_category = 'PAYG_exclient';
                    else customer_category = 'exsubscriber';
                  }
                }
                if (!cookie.get('cusCat')) {
                  cookie.set('cusCat', String(customer_category), 2050, 0, 0, '/');
                }
                analytics.emitEvent('user_data', {
                  GTM: {
                    user_id: context?.user_ctx.id,
                    // TODO: Add field
                    user_presentations_number: 1,
                    // TODO: Fix as
                    customer_category: customer_category as CustomerCategory,
                  },
                });
              }
            });
          const url = new URL(location.href);
          const nextParam = url.searchParams.get('next');

          if (!nextParam) {
            // Если параметра next нет, перенаправляем на route_path.create
            return navigate(route_path.create);
          }

          const next = decodeURIComponent(nextParam);
          const forbiddenRoutes = ['logout', 'setup-style', 'upload-draft', 'login'];

          // Проверяем, содержит ли next запрещённые маршруты
          if (forbiddenRoutes.some((route) => next.toLowerCase().includes(route))) {
            return navigate(route_path.create);
          }

          // Если next валиден, убираем параметр и переходим
          url.searchParams.delete('next');
          url.pathname = next;
          location.href = url.pathname; // Переход по valid next
        } else {
          if (
            resultOfLogin.errors !== null &&
            typeof resultOfLogin.errors === 'object' &&
            '__all__' in resultOfLogin.errors
          ) {
            throw new Error(resultOfLogin.errors?.__all__?.[0] ?? 'Unknown error');
          } else {
            throw new Error('Unknown error');
          }
        }
      })
      .catch((error) => {
        setError('password', { type: 'serverError', message: error.message });
      });
  }

  return (
    <div className="auth_wrap auth_wrap-new">
      <div className="auth_info_wrap">
        <div className="auth_info">
          <img className="auth_info_img" src="/static/i/w16.webp" alt="W" />
          <div className="auth_info_title">
            {LNG.welcome}
            <br />
            {LNG.see_you}
          </div>
          <div className="auth_info_text">{LNG.header_desc}</div>
        </div>
      </div>

      <div className={'auth auth-login' + (authType === 'email' ? ' auth-email' : '')}>
        <div className="auth_block">
          <div className="auth_menu">
            <span className="auth_menu_item active">{t.Login}</span>
            <Link
              className="auth_menu_item"
              to={'/register/' + (location.search.includes('?next=') ? location.search : '')}
            >
              {t.Signup}
            </Link>
          </div>

          {authType === 'email' && (
            <form className="auth_form" onSubmit={handleSubmit(submitHandler)}>
              <div className="auth_row auth_row-email">
                <input
                  className="auth_input"
                  {...register('username', {
                    required: { value: true, message: LNG.errors.required },
                  })}
                  type="email"
                  placeholder={t.Email}
                  required
                  autoFocus
                />
                {errors.username && (
                  <div className="auth_error auth_error-all">{errors.username?.message}</div>
                )}
              </div>

              <div className="auth_row auth_row-pwd">
                <input
                  className="auth_input"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: { value: true, message: LNG.errors.required },
                  })}
                  placeholder={t.Password}
                  autoComplete="current-password"
                />
                <span
                  className="auth_show_pwd"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                ></span>
                {errors.password && (
                  <div className="auth_error auth_error-all">{errors.password?.message}</div>
                )}
              </div>

              <button
                className={cn(
                  'auth_submit gen_btn_main',
                  isLoginLoading && isUserInitLoading && 'opacity-80 animate-pulse',
                )}
                disabled={isLoginLoading || isUserInitLoading || isSubmitting}
                type="submit"
              >
                {t.Login}
              </button>
            </form>
          )}

          {commonContext?.social_auth_available && (
            <a
              className="auth_google_btn"
              href={'/social/login/google-oauth2/' + location.search}
              onClick={() => {
                window.sessionStorage.setItem('auth_by_google', '1');
              }}
            >
              {t.ContinueWithGoogle}
            </a>
          )}

          {authType === 'google' && commonContext?.social_auth_available && (
            <span
              className="auth_by_email about_plain_link"
              onClick={() => {
                setAuthType('email');
              }}
            >
              {t.LoginByEmail}
            </span>
          )}

          {authType === 'email' && (
            <Link className="auth_forgot_link about_plain_link" to="/password-reset/">
              {t.IForgotMyPassword}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
