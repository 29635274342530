import { cva } from 'class-variance-authority';

export const cvaRoot = cva(
  ['header-styles-cvaRoot', 'relative', 'transition-colors ease-in duration-250'],
  {
    variants: {
      isTransparent: {
        true: 'bg-general_background_MIII_500_dark/0',
        false: null,
      },
    },
    defaultVariants: {
      isTransparent: false,
    },
  },
);

export const cvaMainHeader = cva([
  'header-styles-cvaMainHeader',
  'flex items-center px-10 pt-8 pb-6',
]);

export const cvaContainer = cva(['header-styles-cvaContainer', 'w-full flex items-center']);

export const cvaLogoLink = cva(['header-styles-cvaLogoLink', 'shrink-0 pointer-events-auto']);

export const cvaLogo = cva([
  'header-styles-cvaLogo',
  'w-[200px] h-[24px] text-general_background_MIII_500',
]);

export const cvaHeaderMainContent = cva([
  'header-styles-cvaHeaderMainContent',
  'flex items-center justify-between flex-grow gap-8 ml-10',
  'semiMd-max:hidden',
]);

export const cvaHeaderNavMenu = cva(['header-styles-cvaHeaderNavMenu', 'flex items-center gap-7']);

export const cvaMenuItem = cva(
  [
    'header-styles-cvaMenuItem',
    'relative flex items-center gap-x-1.5',
    'hover:text-general_MI_500_default',
    'transition-colors',
    'whitespace-nowrap',
    'before:content-[""] before:absolute before:w-1 before:h-1 before:-left-4 before:rounded before:pointer-events-none',
    'first:before:hidden',
  ],
  {
    variants: {
      isActive: {
        true: 'text-general_MI_500_default',
        false: '',
      },
      isDotHidden: {
        true: 'before:hidden',
        false: '',
      },
      isFontSize20: {
        true: 'text-font_ds_title_body_body_20',
        false: '',
      },
      isTransparent: {
        true: 'text-general_text_MII_default before:bg-general_text_MII_default',
        false: 'text-general_text_MII_secondary_i before:bg-general_text_MII_secondary_i',
      },
    },
    defaultVariants: {
      isTransparent: false,
      isDotHidden: false,
      isFontSize20: false,
    },
  },
);

export const cvaActionsMenu = cva(['header-styles-cvaAuthMenu', 'flex items-center gap-7 ml-auto']);

export const cvaDaysLeft = cva(
  [
    'headerStyles-cvaDaysLeft',
    'flex justify-center px-6 py-4 whitespace-nowrap max-w-[28.375rem] xs-max:max-w-100% w-full xs-max:w-auto rounded-lg',
    'text-general_MI_500_default bg-gradient-to-r from-[rgba(0,186,136,0.2)] to-[rgba(0,186,136,0.05)] ',
  ],
  {
    variants: {
      isFontSize20: {
        true: 'text-font_ds_title_body_body_20',
        false: null,
      },
    },
    defaultVariants: {
      isFontSize20: false,
    },
  },
);

export const cvaBurgerButton = cva([
  'header-styles-cvaBurgerButton',
  'ml-auto semiMd:hidden pointer-events-auto',
]);
