import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type WorkspaceState = {
  workspaceId: number | null;
  workspaceUrl: string;
};

const initialState: WorkspaceState = {
  workspaceId: null,
  workspaceUrl: '',
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspace: (state, action: PayloadAction<{ workspaceId: number; workspaceUrl: string }>) => {
      state.workspaceId = action.payload.workspaceId;
      state.workspaceUrl = action.payload.workspaceUrl;
    },
    clearWorkspace: (state) => {
      state.workspaceId = null;
      state.workspaceUrl = '';
    },
  },
});

export const { setWorkspace, clearWorkspace } = workspaceSlice.actions;
export default workspaceSlice.reducer;
