/**
 * Creates a custom event that bubbles up through the DOM
 * @param el - DOM element
 * @param name - Name of the CustomEvent
 * @param detail - Detail field of the CustomEvent
 * @param params - Other params of the CustomEvent
 * @see https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events
 * @example
 * // How to create custom event with user data and bubble it on document element?
 * bubble(document, "myEvent", { myData: "test" });
 *
 * // How to create custom event and bubble it on specific node?
 * const myEl = document.querySelector("#myElement");
 * if (myEl) {
 *   bubble(myEl, "myEvent");
 * }
 *
 * // How to listen custom events? Use your listener before calling of bubble function.
 * document.addEventListener("myEvent", (e) => console.log(e));
 */
export const bubble = (
  el: HTMLElement | Document | Window = document,
  name: string,
  detail?: CustomEventInit['detail'],
  params: CustomEventInit = {},
): void => {
  if (typeof name !== 'string' || name.trim() === '') {
    throw new Error('The event name must be a non-empty string');
  }

  const eventParams: CustomEventInit = {
    cancelable: true,
    bubbles: true,
    detail,
    ...params,
  };

  if (typeof dispatchEvent === 'function' && typeof CustomEvent === 'function') {
    const event = new CustomEvent(name, eventParams);
    el.dispatchEvent(event);
  }
};
