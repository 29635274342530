import { create } from 'zustand';

interface LoggingState {
  loggingEnabled: Record<string, boolean>; // Key - component name, value - whether logging is enabled for it
  toggleLogging: (componentOrAction: string) => void;
  setLogging: (componentOrAction: string, enabled: boolean) => void;
}

export const useLoggingStore = create<LoggingState>((set) => ({
  loggingEnabled: {},
  toggleLogging: (componentOrAction) =>
    set((state) => ({
      loggingEnabled: {
        ...state.loggingEnabled,
        [componentOrAction]: !state.loggingEnabled[componentOrAction],
      },
    })),
  setLogging: (componentOrAction, enabled) =>
    set((state) => ({
      loggingEnabled: {
        ...state.loggingEnabled,
        [componentOrAction]: enabled,
      },
    })),
}));
