import { prepareHeaders } from '@app/app/lib/api';

import { serverPath } from '@app/utils/server-path';

import type { SlideApiResponse } from '../../types/api';

export const fetchSlidesData = async (workspaceId: string | number): Promise<SlideApiResponse> => {
  const response = await fetch(`${serverPath.workspace.initialSlidesData}${workspaceId}`, {
    headers: prepareHeaders(),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch slides data');
  }

  return response.json();
};
