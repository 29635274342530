import { FC, MouseEvent } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useOutletContext } from 'react-router';

import t from '@lib/lng';
import { route_path } from '@app/utils/route_path';
import { useLogOutMutation } from '@app/store/api/user.api';
import { CommonOutletContext } from '@app/pages/common.outlet';
import {
  CogIcon,
  CreditCardIcon,
  CrownIcon,
  DownloadCloudIcon,
  LogoutIcon,
  MagicWandIcon,
  PickColorIcon,
  StarIcon,
  UserCircleIcon,
  UserIcon,
  UsersIcon,
} from '@app/components/ui/icons';
import { cvaIcon } from '@app/shared/ui/elements';
import { Button } from '@app/shared/ui/button';
import { cn } from '@app/app/lib';

import { useHeaderStore } from '../../model/header.store';

import { cvaMenuItem } from '../header-styles';

import { cvaButton, cvaBadge, cvaProfileEmail } from './header-popover-styles';
import { HeaderPopover } from './header-popover';

type AccountHeaderPopoverProps = { daysLeft: number | null; isTransparentStyles: boolean };

export const AccountHeaderPopover: FC<AccountHeaderPopoverProps> = ({
  isTransparentStyles,
  daysLeft,
}) => {
  const { setEmailNotify, setShowPayments, setShowSettings, clearUserCtx } =
    useOutletContext<CommonOutletContext>();

  const { toggleHeaderMenuOpen } = useHeaderStore();
  const handleNavigationClick = () => toggleHeaderMenuOpen(false);

  const [logoutQuery, { isLoading: isLoadingLogoutMutation }] = useLogOutMutation();

  const logOutHandler = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logoutQuery().then(() => {
      handleNavigationClick();
      clearUserCtx();
      // navigate(route_path.main);
      // window.location.reload();
      window.location.href = route_path.main; //Need reload page after logout
    });
  };

  const { user_ctx, context, commonContext } = useOutletContext<CommonOutletContext>();

  const group = context?.group;
  const TAG = context?.TAG;
  const product_balance = context?.product_balance;

  return (
    <HeaderPopover
      Trigger={
        <button type="button" className={cvaMenuItem({ isTransparent: isTransparentStyles })}>
          <UserIcon className={cvaIcon()} />
          <span>Account</span>
        </button>
      }
    >
      <div className="grid grid-cols-1 max-w-full color-white min-w-[15rem] ">
        {/* Email */}
        {user_ctx?.username && (
          <div className={cvaProfileEmail()}>
            {product_balance?.is_active &&
            !product_balance?.product.is_trial &&
            product_balance.type === 'subscription' ? (
              <CrownIcon className={cn(cvaIcon(), 'text-secondary_warning_SI_400_warning')} />
            ) : (
              <UserCircleIcon className={cvaIcon()} />
            )}

            <span className="truncate">{user_ctx.username}</span>
          </div>
        )}

        {/* Confirm email button */}
        {!user_ctx?.is_confirmed && (
          <button type="button" onClick={() => setEmailNotify(true)} className={cvaBadge()}>
            Confirm your email
          </button>
        )}

        {/* Days left*/}
        {daysLeft !== null && (
          <NavLink to="/pricing/" className={cvaBadge()}>
            {daysLeft > 0 ? t.formatString(t.trialExpiresInDays, daysLeft) : t.Less1DayTrial}
          </NavLink>
        )}

        {/* Product expired alert */}
        {!product_balance?.is_active && product_balance?.product && (
          <div className={cvaBadge()}>
            {product_balance.product.is_trial
              ? t.formatString(t.TrialExpiredPlease, <br />)
              : t.formatString(t.SubscriptionExpiredPlease, <br />)}
          </div>
        )}

        {/* PayGo Product balance alert */}
        {product_balance?.is_active &&
          product_balance?.product &&
          !product_balance?.product.is_trial &&
          product_balance.type === 'presentation' && (
            <div className={cvaBadge()}>
              <DownloadCloudIcon className={cvaIcon()} />
              <span>
                Downloads {product_balance.count} out of {product_balance.product.count}
              </span>
            </div>
          )}

        {/* Profile tags TODO: Check */}
        {Object.keys(TAG ?? {}).length > 0 && (
          <div className="">
            {!group ||
              !group[0] ||
              (group[0] === t.admin && <StarIcon className={cvaIcon({ size: 'small' })} />)}
            {group && group[0] ? <span>{group[0]}</span> : <span>{t.Owner}</span>}
          </div>
        )}

        {/* Payments button */}
        {user_ctx?.is_confirmed && !commonContext?.boxed && (
          <Button
            variant="secondary"
            size="text16"
            onClick={() => setShowPayments(true)}
            className={cvaButton()}
            disabled={isLoadingLogoutMutation}
          >
            <CreditCardIcon className={cvaIcon()} />
            <span>
              {!product_balance?.is_active && product_balance?.product
                ? 'Upgrade plan'
                : 'Manage plan'}
            </span>
          </Button>
        )}

        {/* Settings button */}
        <Button
          variant="secondary"
          size="text16"
          onClick={() => setShowSettings(true)}
          className={cvaButton()}
          disabled={isLoadingLogoutMutation}
        >
          <CogIcon className={cvaIcon()} />
          <span>Settings</span>
        </Button>
        {/* Admin button */}
        {Object.keys(TAG ?? {}).length > 0 && (
          <>
            <Button
              variant="secondary"
              size="text16"
              className={cvaButton()}
              disabled={isLoadingLogoutMutation}
              asChild
            >
              <Link to={route_path.team} className="inline-flex gap-x-1.5">
                <UsersIcon className={cvaIcon()} />
                <span>{t.Users}</span>
              </Link>
            </Button>

            <Button
              variant="secondary"
              size="text16"
              className={cvaButton()}
              disabled={isLoadingLogoutMutation}
              asChild
            >
              <Link to={route_path.teamAttribute} className="inline-flex gap-x-1.5">
                <PickColorIcon className={cvaIcon()} />
                <span>{t.corporateStyle}</span>
              </Link>
            </Button>
          </>
        )}
        {/* Logout button */}
        <Button
          variant="secondary"
          size="text16"
          onClick={logOutHandler}
          className={cvaButton()}
          disabled={isLoadingLogoutMutation}
        >
          <LogoutIcon className={cvaIcon()} />
          <span>Log out</span>
        </Button>
        {/* Admin button */}
        {user_ctx?.is_superuser && (
          <Button variant="secondary" size="text16" className={cvaButton()} asChild>
            <Link
              to={route_path.admin}
              rel="noreferrer"
              target="_blank"
              className="inline-flex gap-x-1.5"
            >
              <MagicWandIcon className={cvaIcon()} />
              <span>Admin</span>
            </Link>
          </Button>
        )}
      </div>
    </HeaderPopover>
  );
};
