import { ComponentProps, FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { toast, Toaster as Sonner, ExternalToast } from 'sonner';
import { cva, VariantProps } from 'class-variance-authority';
import { clsx } from 'clsx';

import { CheckIcon, CrossIcon, SyncIcon } from '@app/components/ui/icons';
import { cvaIcon } from '@app/shared/ui/elements';

type ToasterProps = ComponentProps<typeof Sonner>;

const cvaToastsList = cva(['sonner-cvaToastsList']);

const cvaCloseButton = cva([
  'sonner-cvaCloseButton',
  'absolute top-2 right-2 text-general_text_MII_hover',
]);

const cvaToastRoot = cva(['sonner-cvaToastRoot', 'w-full px-6 py-4 rounded-lg shadow-toast']);

const cvaToastVariant = cva(['sonner-cvaToastVariant'], {
  variants: {
    success: {
      true: 'bg-general_MI_700_pressed',
      false: null,
    },
    error: {
      true: 'bg-secondary_error_SI_600_error',
      false: null,
    },
    warning: {
      true: 'bg-secondary_warning_SI_600_warning',
      false: null,
    },
    info: {
      true: 'bg-general_text_MII_secondary_i',
      false: null,
    },
  },
  defaultVariants: {
    success: false,
    error: false,
    warning: false,
    info: false,
  },
});

const cvaToastCancelLabel = cva(
  [
    'sonner-cvaToastCancelLabel',
    'inline-flex items-center gap-x-1.5 px-4 py-2 rounded-lg text-font_ds_title_button_buttons_16',
  ],
  {
    variants: {
      success: {
        true: 'bg-general_MI_500_default text-general_text_MII_default',
        false: null,
      },
      error: {
        true: 'bg-general_background_MIII_500 text-secondary_error_SI_500_error',
        false: null,
      },
      warning: {
        true: 'bg-general_background_MIII_500 text-secondary_warning_SI_500_warning',
        false: null,
      },
      info: {
        true: 'bg-general_background_MIII_400_dark text-general_text_MII_default',
        false: null,
      },
    },
    defaultVariants: {
      success: false,
      error: false,
      warning: false,
      info: false,
    },
  },
);

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      position="bottom-right"
      duration={3000}
      className={cvaToastsList()}
      closeButton
      icons={{
        info: <span />,
        success: <span />,
        error: <span />,
        warning: <span />,
        close: <CrossIcon className={cvaIcon({ size: 'small' })} />,
      }}
      toastOptions={{
        classNames: {
          success: cvaToastVariant({ success: true }),
          error: cvaToastVariant({ error: true }),
          warning: cvaToastVariant({ warning: true }),
          info: cvaToastVariant({ info: true }),
          closeButton: cvaCloseButton(),
        },
        className: cvaToastRoot(),
        unstyled: true,
      }}
      {...props}
    />
  );
};

export const ToastLabel: FC<
  PropsWithChildren<HTMLAttributes<HTMLSpanElement> & VariantProps<typeof cvaToastCancelLabel>>
> = ({ success, info, warning, error, className, children, ...props }) => {
  return (
    <span
      className={clsx(cvaToastCancelLabel({ error, warning, info, success }), className)}
      {...props}
    >
      {children}
    </span>
  );
};

const ToastTitle: FC<PropsWithChildren<HTMLAttributes<HTMLParagraphElement>>> = ({
  className,
  ...props
}) => {
  return (
    <p
      className={clsx(
        'text-general_text_MII_default text-font_ds_title_body_black_16 select-none',
        className,
      )}
      {...props}
    />
  );
};

const ToastText: FC<PropsWithChildren<HTMLAttributes<HTMLParagraphElement>>> = ({
  className,
  ...props
}) => {
  return (
    <p
      className={clsx(
        'text-general_text_MII_hover text-font_ds_title_body_body_14 select-none',
        className,
      )}
      {...props}
    />
  );
};

export const ConfiguredToaster = {
  toastError: (message?: ReactNode, config?: ExternalToast) => toast.error(message, config),
  toastUnknownError: (message?: ReactNode, config?: ExternalToast) =>
    toast.error(
      <div>
        <ToastTitle className="mb-1">Oops, something went wrong</ToastTitle>
        <ToastText>
          Don`t worry, we have saved the latest edits. Please refresh this page to access your
          slides
        </ToastText>
      </div>,
      {
        action: (
          <button type="button" onClick={() => window.location.reload()}>
            <ToastLabel error className="mt-4">
              <SyncIcon className={cvaIcon()} />
              <span>Refresh</span>
            </ToastLabel>
          </button>
        ),
        ...config,
      },
    ),
  toastSuccess: (message: ReactNode, config?: ExternalToast) =>
    toast.success(message, {
      cancel: {
        label: (
          <ToastLabel success className="mt-4">
            <CheckIcon className={cvaIcon()} />
            <span>Fine</span>
          </ToastLabel>
        ),
        onClick: () => null,
      },
      ...config,
    }),
  toastWarning: (message: ReactNode, config?: ExternalToast) =>
    toast.warning(message, {
      cancel: {
        label: (
          <ToastLabel warning className="mt-4">
            <CheckIcon className={cvaIcon()} />
            <span>Fine</span>
          </ToastLabel>
        ),
        onClick: () => null,
      },
      ...config,
    }),
  toastInfo: (message: ReactNode, config?: ExternalToast) =>
    toast.info(message, {
      cancel: {
        label: (
          <ToastLabel info className="mt-4">
            <CheckIcon className={cvaIcon()} />
            <span>Understood</span>
          </ToastLabel>
        ),
        onClick: () => null,
      },
      ...config,
    }),
};
export { Toaster, ToastTitle, ToastText };

/* 
--- Toast Example ---
ConfiguredToaster.toastSuccess(
    <div>
      <ToastTitle className="mb-1">Great!</ToastTitle>
      <ToastText>You have now created a PIN code to access your presentation</ToastText>
    </div>,
    { <Options Override> }
)
*/
