import { cva } from 'class-variance-authority';

export const cvaPopoverContent = cva([
  'header-popover-styles-cvaPopoverContent',
  'z-[150] flex flex-col items-start min-w-[15rem] max-w-[15rem] w-full bg-general_background_MIII_450_dark',
]);

export const cvaSeparator = cva([
  'header-popover-styles-cvaSeparator',
  'w-full h-px bg-general_background_MIII_400_dark',
]);

export const cvaButton = cva(
  ['header-popover-styles-cvaButton', 'flex justify-self-start px-4 py-2'],
  {
    variants: {
      isLink: {
        true: 'ml-8',
        false: null,
      },
    },
    defaultVariants: {
      isLink: false,
    },
  },
);

export const cvaBadge = cva([
  'headerStyles-cvaBadge',
  'flex justify-center gap-x-1.5 py-3 whitespace-nowrap w-full',
  'text-font_ds_title_body_black_16 text-general_MI_500_default bg-general_MI_800_disabled',
]);

export const cvaProfileEmail = cva([
  'header-menu-styles-cvaProfileEmail',
  'flex gap-x-1.5 max-w-full px-4 py-2 self-stretch',
  'text-font_ds_title_button_buttons_16 text-general_text_MII_disabled',
]);
