import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { cookie } from '@app/app/lib/cookie';
import { analytics } from '@app/app/analytics';

// TODO: Obsolete header. Remove duplicate
const csrftoken = document.cookie.match(/csrftoken=[^;]+/g)?.[0]?.split('=')?.[1] ?? null;

const prepareHeaders = (csrftoken: string | null) => (headers: Headers) => {
  headers.set('X-CSRFToken', csrftoken ?? String(cookie.get('csrftoken')));
  if (!headers.has('Content-Type')) {
    headers.set('Content-Type', 'application/json');
  } else {
    headers.get('Content-Type') === 'multipart/form-data' && headers.delete('Content-type');
  }
  headers.set('X-Requested-With', 'XMLHttpRequest');
  return headers;
};
const currentHostUrl = String(
  window.location.protocol || 'http:' + '//' + window.location.host + '/',
);
export const appBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: currentHostUrl,
    prepareHeaders: prepareHeaders(csrftoken),
    credentials: 'same-origin',
  });

export const appBaseQueryWithResend: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await appBaseQuery()(args, api, extraOptions);
  if (result.error) {
    const { status } = result.error;
    analytics.emitEvent('track_error', {
      GTM: {
        error_type: 'system_error',
      },
    });
    analytics.emitEvent('service_error_system', {
      Amplitude: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fileID: args?.url?.split('/')?.[2] ?? '',
        action: api?.endpoint,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        httpCode: result.error.originalStatus,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        message: result.error.error,
      },
    });

    if (status === 401 || status === 403) {
      //FIXME: redirect to login page (when will be refresh token endpoint use here)
      window.location.href = '/login';
    }
  }
  return result;
};
