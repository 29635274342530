import { FC, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '@app/shared/ui/dialog';
import { CrossIcon, RenameIcon } from '@app/components/ui/icons';
import { Button } from '@app/shared/ui/button';
import { cvaIcon } from '@app/shared/ui/elements';
import { Input } from '@app/shared/ui/input';
import { cn } from '@app/app/lib';

import { Close } from '@radix-ui/react-dialog';

import { useUpdateWorkspace } from '@app/widgets/canvas/api/hooks/useUpdateWorkspace';

import { cvaRoot, cvaContent, cvaTitle, cvaInput, cvaActions } from './rename-modal-styles';
type RenameModalProps = {
  initialName: string;
  onRename: (newName: string) => void;
  trigger?: React.ReactNode;
  workspaceId: string;
};

export const RenameModal: FC<RenameModalProps> = ({
  initialName = 'Untitled',
  onRename,
  trigger,
  workspaceId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [presentationName, setPresentationName] = useState(initialName);
  const { mutate: renamePresentation, isPending, isSuccess } = useUpdateWorkspace();

  const handleRename = () => {
    if (presentationName.trim() && presentationName !== initialName) {
      renamePresentation({
        workspaceId: workspaceId,
        description: presentationName,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onRename(presentationName);
      setIsOpen(false);
    }
  }, [isSuccess, onRename, presentationName]);

  const handleOpenChange = (open: boolean) => {
    setPresentationName(initialName);
    setIsOpen(open);
  };

  const hasNameChanged = presentationName.trim() !== initialName.trim();

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        {trigger || (
          <button className="text-general_MI_500_default">
            <RenameIcon className={cvaIcon({ size: 'small' })} />
          </button>
        )}
      </DialogTrigger>
      <DialogContent
        className={cvaRoot()}
        customCloseIcon={
          <Close
            aria-label="Close"
            className="absolute right-2 top-2 transition-colors disabled:pointer-events-none text-general_text_MII_secondary_i hover:text-general_text_MII_secondary_ii focus-visible:outline"
            disabled={false} //TODO: isPending
          >
            <CrossIcon className={cvaIcon()} />
            <span className="sr-only">Close</span>
          </Close>
        }
      >
        <div className={cvaContent()}>
          <DialogTitle className={cvaTitle()}>Rename Presentation</DialogTitle>
          <Input
            className={cn(cvaInput())}
            value={presentationName}
            onChange={(e) => setPresentationName(e.target.value)}
            placeholder="Enter presentation name"
            autoFocus
          />
          <div className={cvaActions()}>
            <Button
              onClick={handleRename}
              disabled={!presentationName.trim() || !hasNameChanged || isPending}
              isLoading={isPending}
            >
              Rename
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
