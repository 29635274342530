import { cva } from 'class-variance-authority';

export const cvaMenuContentMobile = cva([
  'mobile-menu-styles-cvaMenuContentMobile',
  'overflow-y-auto',
  'fixed inset-0 flex',
  'z-[100] bg-general_background_MIII_500_dark',
  'data-[state=open]:animate-in ease-in data-[state=closed]:animate-out duration-250',
  'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
]);

export const cvaInner = cva([
  'mobile-menu-styles-cvaInner',
  'relative py-10 grow',
  'flex flex-col items-center',
]);

export const cvaEmail = cva([
  'mobile-menu-styles-cvaEmail',
  'flex gap-x-1.5 text-font_ds_title_body_body_20 text-general_text_MII_disabled',
]);

export const cvaActionsMenuMobile = cva([
  'mobile-menu-styles-cvaActionsMenuMobile',
  'flex flex-col items-center gap-6 w-full',
]);

export const cvaNavMenuMobile = cva([
  'mobile-menu-styles-cvaNavMenuMobile',
  'min-w-[10.125rem]',
  'flex items-center flex-col gap-6',
]);

export const cvaSeparator = cva([
  'mobile-menu-styles-cvaSeparator',
  'h-px my-8 w-[10.125rem] bg-general_background_MIII_400_dark shrink-0',
]);

export const cvaCloseButton = cva([
  'mobile-menu-styles-cvaCloseButton',
  'absolute top-2 right-2 text-general_text_MII_secondary_i',
]);

export const cvaBadge = cva([
  'headerStyles-cvaBadge',
  'flex justify-center gap-x-1.5 px-6 py-4 whitespace-nowrap max-w-[28.375rem] xs-max:max-w-100% w-full xs-max:w-auto rounded-lg',
  'text-font_ds_title_body_black_20 text-general_MI_500_default bg-general_MI_800_disabled',
]);
