import { FC } from 'react';
import { Outlet, useOutletContext } from 'react-router';

import { StageHeader } from '@app/widgets/header';
import { CommonOutletContext } from '@app/pages/common.outlet';

export const StageLayout: FC<{ isWorkspaceVariant?: boolean }> = ({
  isWorkspaceVariant = false,
}) => {
  const context = useOutletContext<CommonOutletContext>();

  return (
    <>
      <StageHeader isWorkspaceVariant={isWorkspaceVariant} />
      <main
        className={`min-h-[calc(100vh-5rem)] flex flex-col px-5 ${
          isWorkspaceVariant ? 'min-w-[984px]' : ''
        }`}
      >
        <Outlet context={context} />
      </main>
    </>
  );
};
