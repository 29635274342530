import { create } from 'zustand';
import FontFaceObserver from 'fontfaceobserver';

import { useLoggingStore } from './logger';

type FontStore = {
  loadedFonts: { [fontName: string]: boolean };
  loadFont: (fontName: string, fontUrl: string) => Promise<void>;
  setFontsToLoad: (fonts: { name: string; url: string }[]) => void;
  fontsToLoad?: { name: string; url: string }[];
};

export const useFontStore = create<FontStore>((set) => ({
  loadedFonts: {},
  fontsToLoad: [],
  loadFont: async (fontName, fontUrl) => {
    const { loggingEnabled } = useLoggingStore.getState();
    set((state) => {
      if (state.loadedFonts[fontName]) {
        if (loggingEnabled['loadFont']) {
          console.debug(`Font ${fontName} is already loaded.`);
        }
        return state;
      }

      const font = new FontFaceObserver(fontName);
      font
        .load(fontUrl, 10000)
        .then(() => {
          set((state) => ({
            loadedFonts: {
              ...state.loadedFonts,
              [fontName]: true,
            },
          }));
          if (loggingEnabled['loadFont']) {
            console.debug(`🖊️ Font ${fontName} loaded successfully`);
          }
        })
        .catch((error) => {
          if (loggingEnabled['loadFont']) {
            console.error(`⛔ Error loading font ${fontName}:`, error);
          }
        });

      return state;
    });
  },
  setFontsToLoad: (fonts) => {
    set({ fontsToLoad: fonts });
  },
}));
