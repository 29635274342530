import { create } from 'zustand';

import type { MenuItem } from '../types/types';

export interface ContextMenuState {
  contextMenuPos: { x: number; y: number } | null;
  menuItems: MenuItem[];
  setContextMenuPos: (pos: { x: number; y: number } | null) => void;
  setMenuItems: (items: MenuItem[]) => void;
  resetMenu: () => void;
}

export const useContextMenuStore = create<ContextMenuState>((set) => ({
  contextMenuPos: null,
  menuItems: [],

  setContextMenuPos: (pos) => {
    set({ contextMenuPos: pos });
  },

  setMenuItems: (items) => {
    set({ menuItems: items });
  },

  resetMenu: () => {
    set({ contextMenuPos: null, menuItems: [] });
  },
}));
