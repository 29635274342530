import { Dispatch, FC, SetStateAction, useEffect, useState, useCallback } from 'react';
import { Outlet } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { analytics, amplitudeInstance } from '@app/app/analytics';
import { SignInUpMethod } from '@app/app/analytics/types';
import { registerAnalytics } from '@app/app/analytics/register.analytics';
import { LoadingScreen, TrialPopup } from '@app/components/template';
import { route_path } from '@app/utils/route_path';
import { CommonInit, UserData, UserDataType } from '@app/types/user.type';
import {
  useGetCommonInitQuery,
  useGetUserInitQuery,
  useLogOutMutation,
} from '@app/store/api/user.api';

import { TrialType } from '@app/types/trial.type';

import { AnimatePresence, motion } from 'framer-motion';

import { AccountConfirmNotify, ResendConfirmAccountNotify } from '@app/components/template';

import Footer from '@app/common/footer';
import { cookie } from '@app/app/lib/cookie';
import { useAppDispatch, useAppSelector } from '@app/hooks/use-store';
import { checkAuth } from '@app/store/slice/auth.slice';
import { PaymentsDialog } from '@app/widgets/payments-dialog';
import { SettingsDialog } from '@app/widgets/settings-dialog';
import { gtmInstance } from '@app/app/analytics/analytics';
import { WelcomeMessageModal } from '@app/modals/WelcomeMessage';

const ONBOARD_PRESENTATION_TYPE = 'presentation';

type PopupState = {
  type: TrialType | null;
  callback: (() => void) | null;
  callBack?: () => void;
  onClose: (() => void) | null;
};

export type CommonOutletContext = {
  user_ctx?: UserData['user_ctx'];
  updateData: UserDataType;
  logout: () => void;
  context?: UserData;
  commonContext?: CommonInit;
  onboardingMessage: (
    type: string,
    keepSteps?: boolean,
  ) => (({ step, freeze }: { step: number; freeze: number }) => void)[];
  changePresentationType: Dispatch<SetStateAction<'pptx' | 'draft'>>;
  setTrialPopup: Dispatch<SetStateAction<PopupState>>;
  setEmailNotify: Dispatch<SetStateAction<boolean>>;
  setSendConfirmNotify: Dispatch<SetStateAction<boolean>>;
  setShowSettings: Dispatch<SetStateAction<boolean>>;
  setShowPayments: Dispatch<SetStateAction<boolean>>;
  clearUserCtx: () => void;
};

const PROTECTED_ROUTE_LIST = ['create', 'project', 'projects', 'setup-style', 'upload-draft'];

const foldSignMethod = (method: string): SignInUpMethod => {
  switch (method.toLowerCase()) {
    case 'google':
      return 'google';
    case 'email':
      return 'email';
    default:
      throw Sentry.captureException(`Invalid sign-in method: ${method}`);
  }
};

const CommonOutlet: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, isLoading: isAuthCheckLoading } = useAppSelector(
    (state) => state.authState,
  );
  const [logout] = useLogOutMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: commonInit, isLoading: isCommonInitLoading } = useGetCommonInitQuery(null);

  const {
    data: userInit,
    isLoading: isUserInitLoading,
    isSuccess,
    refetch,
  } = useGetUserInitQuery(null, { skip: !isAuthenticated });

  const [trialPopup, setTrialPopup] = useState<PopupState>({
    type: null,
    callback: null,
    onClose: null,
  });

  // State for welcome message modal
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [buttonText, setButtonText] = useState('');

  const checkAndSetEmailNotify = useCallback(() => {
    const emailNotifyShown = sessionStorage.getItem('emailNotifyShown');
    if (!emailNotifyShown && isAuthenticated && !userInit?.result?.user_ctx?.is_confirmed) {
      sessionStorage.setItem('emailNotifyShown', 'true');
      return true;
    }
    return false;
  }, [isAuthenticated, userInit]);

  const [emailNotify, setEmailNotify] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showPayments, setShowPayments] = useState(false);
  const [sendConfirmNotify, setSendConfirmNotify] = useState(false);
  const [onboardingState, changeOnboardingState] = useState<{ [type: string]: number }>({});
  const [presentationType, changePresentationType] =
    useState<CommonOutletContext['changePresentationType']['arguments']>('pptx');

  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [userCtx, setUserCtx] = useState<UserData['user_ctx'] | undefined>(
    userInit?.result?.user_ctx,
  );

  const withoutFooterLocations = ['project', 'create', 'workspace'];

  const apiKey = commonInit?.result?.amplitude_key;
  const mode = commonInit?.result?.DEV ? 'development' : 'production';

  useEffect(() => {
    const performAuthCheck = async () => {
      await dispatch(checkAuth());
      setIsAuthChecked(true);
    };

    performAuthCheck();
  }, [dispatch]);

  useEffect(() => {
    analytics.switchMode(mode);
  }, [mode]);

  const countryCode = commonInit?.result?.GEO;
  useEffect(() => {
    if (typeof countryCode === 'string') {
      gtmInstance.setCountry(countryCode);
    }
    analytics.registerPlatform('GTM', gtmInstance);
  }, [countryCode]);

  useEffect(() => {
    if (!apiKey) return;
    amplitudeInstance.init(apiKey, {
      defaultTracking: {
        pageViews: true,
      },
      // NOTE: Our passed UserId throw and error "Invalid user_id or device_id.
      minIdLength: 1,
    });

    analytics.registerPlatform('Amplitude', amplitudeInstance);
  }, [apiKey]);

  useEffect(() => {
    if (isAuthenticated && userInit) {
      const emailNotifyResult = checkAndSetEmailNotify();
      setEmailNotify(emailNotifyResult);
    }
  }, [isAuthenticated, userInit, checkAndSetEmailNotify]);

  function onboardingMessage(type: string, keepSteps: boolean = true) {
    return [
      () => {
        if (presentationType === 'pptx' && type === ONBOARD_PRESENTATION_TYPE) {
          return 1;
        }
        if (
          onboardingState?.['type'] === 1 &&
          window.localStorage.getItem(`${type}_onboard_index`) !==
            window.localStorage.getItem('onboard_freeze')
        ) {
          window.localStorage.setItem(
            `${type}_onboard_index`,
            window.localStorage.getItem(`${String(type)}_onboard_freeze`) ?? '1',
          );
          return window.localStorage.getItem(`${type}_onboard_freeze`) ?? 1;
        }
        return window.localStorage.getItem(`${type}_onboard_index`) ?? onboardingState?.[type] ?? 1;
      },
      ({ step, freeze }: { step: number; freeze: number }) => {
        if (presentationType === 'pptx' && type === ONBOARD_PRESENTATION_TYPE) return;
        const localStep = window.localStorage.getItem(`${type}_onboard_index`);
        if (Number(localStep) >= Number(step) && keepSteps) return;
        window.localStorage.setItem(`${type}_onboard_index`, String(step));
        window.localStorage.setItem(`${type}_onboard_freeze`, String(freeze));
        changeOnboardingState((prevState) => ({ ...prevState, [type]: Number(step) }));
      },
    ];
  }

  function updateData(callback: (() => void) | undefined) {
    refetch().then(() => {
      callback?.();
    });
  }

  function logoutHandler() {
    logout()
      .unwrap()
      .then(() => {
        navigate(route_path.main);
      });
  }

  function clearUserCtx() {
    setUserCtx(undefined);
  }

  function trackAuth(userId: number) {
    // TODO: Unused variable
    // let accountsOnDevice = 1;

    const accountsFromStorage = window.localStorage.getItem('accounts');

    if (accountsFromStorage) {
      const accounts = JSON.parse(accountsFromStorage);
      if (!accounts.includes(userId)) {
        accounts.push(userId);
        window.localStorage.setItem('accounts', JSON.stringify(accounts));
      }
      // TODO: Unused variable
      // accountsOnDevice = accounts.length;
    } else {
      window.localStorage.setItem('accounts', JSON.stringify([userId]));
    }

    let method = 'email';
    if (window.sessionStorage.getItem('auth_by_google')) {
      method = 'google';
    }

    if (
      window.sessionStorage.getItem('login') ||
      (window.sessionStorage.getItem('auth_by_google') && !cookie.get('oauth_reg'))
    ) {
      analytics.emitEvent('login', {
        GTM: {
          method: foldSignMethod(method),
        },
      });

      window.sessionStorage.removeItem('login');
      window.sessionStorage.removeItem('auth_by_google');
    } else if (window.sessionStorage.getItem('reg')) {
      registerAnalytics.userRegistered({ method: foldSignMethod(method), userId });
    }
  }

  function clearConfirmNotifyAndClose() {
    setSendConfirmNotify(false);
    navigate(location.pathname);
  }

  function closeTrialPopupHandler() {
    trialPopup?.onClose?.();
    setTrialPopup({ type: null, callback: null, onClose: null });
  }

  // Show modal for successful email confirmation
  useEffect(() => {
    if (window.location.search.includes('email-confirmed=True')) {
      //window.history.replaceState({}, document.title, location.href.replace(/(\?|&)+email-confirmed=True/, ''));
      setSendConfirmNotify(true);
      if (window.localStorage.getItem('genState')) {
        const savedState = JSON.parse(String(window.localStorage.getItem('genState')) ?? {});
        window.localStorage.removeItem('genState');
        window.location.href = `${route_path.project}${savedState.projectId}/`;
      }
    }
  }, []);

  useEffect(() => {
    const pendingMessage = localStorage.getItem('pendingWelcomeMessage');
    if (pendingMessage && localStorage.getItem('hasSeenWelcomeMessage') !== 'true') {
      setWelcomeMessage(pendingMessage);
      setShowWelcomeModal(true);
      localStorage.removeItem('pendingWelcomeMessage');
    }
    const pendingButtonText = localStorage.getItem('pendingWelcomeMessageButtonText');
    if (pendingButtonText) {
      setButtonText(pendingButtonText);
      localStorage.removeItem('pendingWelcomeMessageButtonText');
    }
  }, [location.pathname]);

  const handleCloseWelcomeModal = () => {
    setShowWelcomeModal(false);
    localStorage.setItem('hasSeenWelcomeMessage', 'true');
  };

  useEffect(() => {
    if (window.sessionStorage.getItem('buyFromTrial')) {
      window.sessionStorage.removeItem('buyFromTrial');
      if (
        !isUserInitLoading &&
        userInit?.result?.product_balance &&
        !userInit?.result?.product_balance?.product?.is_trial
      ) {
        if (userInit.result.product_balance?.product?.count) {
          analytics.emitEvent('trial_end', {
            GTM: {
              trial_end_reason: 'buy_payg',
            },
          });
        } else {
          analytics.emitEvent('trial_end', {
            GTM: {
              trial_end_reason: 'buy_subscription',
            },
          });
        }
      }
    }
  }, [isUserInitLoading, userInit]);

  useEffect(() => {
    if (userInit?.result?.user_ctx?.id) {
      amplitudeInstance.getInstance().setUserId(String(userInit?.result?.user_ctx?.id));
      if (cookie.get('uid') && cookie.get('uid') !== String(userInit.result.user_ctx.id)) {
        cookie.set('uid', String(userInit.result.user_ctx.id), 2050, 0, 0, '/');
      }
      trackAuth(userInit.result.user_ctx.id);
    }
  }, [userInit, location?.pathname, isSuccess, navigate]);

  useEffect(() => {
    if (location.pathname.includes('pricing')) {
      dispatch(checkAuth());
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (
      isAuthChecked &&
      !isAuthenticated &&
      !isAuthCheckLoading &&
      PROTECTED_ROUTE_LIST.includes(location.pathname.split('/')[1])
    ) {
      const next = encodeURIComponent(location.pathname);
      //!! Important
      const isLogout =
        next === encodeURIComponent('/logout') || next === encodeURIComponent('/logout/');

      if (!isLogout) {
        navigate(`${route_path.register}?next=${next}`, { replace: true });
      } else {
        navigate(route_path.register);
      }
    }
  }, [dispatch, isAuthenticated, isAuthCheckLoading, location.pathname, navigate, isAuthChecked]);

  useEffect(() => {
    const projectId = location.pathname.match(/[0-9]+\/$/);
    if (projectId) {
      analytics.emitEvent('page_view', {
        Amplitude: {
          URL: location.pathname,
          'processed file id': +projectId[0].replace('/', ''),
        },
      });
    } else {
      if (userInit?.result?.user_ctx) {
        analytics.emitEvent('page_view', {
          Amplitude: {
            URL: location.pathname,
          },
        });
      }
    }
  }, [location.pathname, userInit?.result?.user_ctx]);

  useEffect(() => {
    if (userInit?.result?.user_ctx) {
      setUserCtx(userInit.result.user_ctx);
    } else {
      setUserCtx(undefined);
    }
  }, [userInit]);

  const isWorkspacePath = [
    route_path.workspaceCreate,
    route_path.workspaceSetupStyle,
    route_path.workspaceUploadDraft,
  ].some((path) => location.pathname.includes(path));

  useEffect(() => {
    document.body.style.backgroundColor = isWorkspacePath ? '#191B20' : '#202020';
  }, [isWorkspacePath]);

  if (isUserInitLoading || isCommonInitLoading || isAuthCheckLoading) return <LoadingScreen />;

  return (
    <>
      <Outlet
        context={
          {
            user_ctx: userCtx,
            logout: logoutHandler,
            clearUserCtx,
            context: userInit?.result,
            commonContext: commonInit?.result,
            setShowSettings,
            setShowPayments,
            updateData,
            onboardingMessage,
            changePresentationType,
            setEmailNotify,
            setSendConfirmNotify,
            setTrialPopup,
          } satisfies CommonOutletContext
        }
      />
      {!withoutFooterLocations.includes(location.pathname.split('/')[1]) ? (
        <Footer commonContext={commonInit?.result} />
      ) : (
        <></>
      )}
      <div className="absolute z-[151] bottom-[-500px]">
        <AnimatePresence>
          {showWelcomeModal && welcomeMessage && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="z-[50]"
            >
              <WelcomeMessageModal
                message={welcomeMessage}
                isOpen={showWelcomeModal}
                onClose={handleCloseWelcomeModal}
                buttonText={buttonText}
              />
            </motion.div>
          )}
          {trialPopup.type && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="z-[50]"
            >
              <TrialPopup
                type={trialPopup.type}
                onClickDownload={() => {
                  const callback = trialPopup?.callback || trialPopup?.callBack; //TODO: Using lowercase to avoid issues with inconsistent casing
                  if (typeof callback === 'function') {
                    callback();
                  }
                }}
                context={userInit?.result}
                close={closeTrialPopupHandler}
              />
            </motion.div>
          )}
          {emailNotify && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ResendConfirmAccountNotify
                close={() => {
                  setEmailNotify(false);
                }}
                linkSended={true}
              />
            </motion.div>
          )}
          {sendConfirmNotify && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <AccountConfirmNotify close={clearConfirmNotifyAndClose} />
            </motion.div>
          )}
          {userInit && (
            <>
              <SettingsDialog
                isOpen={showSettings}
                setOpen={setShowSettings}
                experimental_features={
                  userInit?.result?.user_options &&
                  userInit?.result?.user_options.experimental_features
                    ? userInit?.result?.user_options.experimental_features
                    : false
                }
                isShowExperimental={commonInit?.result?.experimental_features_available || false}
              />

              <PaymentsDialog
                isOpen={showPayments}
                setOpen={setShowPayments}
                product_balance={userInit?.result?.product_balance}
                subscriptionActive={userInit?.result?.subscription_active}
              />
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default CommonOutlet;
