import { useQuery } from '@tanstack/react-query';

import { SlideApiResponse } from '../../types/api';

import { fetchSlidesData } from '../queries/fetchSlidesData';

export const useSlides = (workspaceId?: string) => {
  return useQuery<SlideApiResponse, Error>({
    queryKey: ['slides', workspaceId],
    queryFn: () => {
      if (!workspaceId) return Promise.reject(new Error('No workspaceId provided'));
      return fetchSlidesData(workspaceId);
    },
    enabled: !!workspaceId,
    retry: 0,
  });
};
