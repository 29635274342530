/* eslint-disable @typescript-eslint/no-explicit-any */
import { Canvas, Object as FabricObject, Group } from 'fabric';

import { useCanvasStore } from '../store';

import { generateObjectId } from './generateId';

import { loadObjectsWithLayers } from './helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const copyObjects = async (canvas: Canvas, canvasId: string) => {
  const activeObjects = canvas.getActiveObjects();
  if (activeObjects && activeObjects.length > 0) {
    const copied = activeObjects.map(async (obj) => {
      if (!obj.noCopy) {
        const clone = await obj.clone();
        // Восстанавливаем оригинальную прозрачность при копировании
        if (obj.originalOpacity !== undefined) {
          clone.opacity = obj.originalOpacity;
          delete clone.originalOpacity;
        }
        return clone;
      } else {
        return null;
      }
    });

    const settledPromises = await Promise.allSettled(copied);
    const clonedObjects = settledPromises
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result.value);

    clonedObjects.forEach((obj) => {
      if (obj) {
        obj.set('id', generateObjectId(canvasId));
      }
    });

    return clonedObjects;
  }
  return [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pasteObjects = async (
  canvas: Canvas,
  objectsToPaste: any[],
  targetCanvasId: string,
  options?: {
    insertCoordinates?: { left: number; top: number };
    offsetX?: number;
    offsetY?: number;
  },
) => {
  if (objectsToPaste.length > 0) {
    const defaultOffsetX = options?.offsetX ?? 30;
    const defaultOffsetY = options?.offsetY ?? 30;

    const resToPaste = await Promise.all(objectsToPaste.map((obj) => obj.clone()));

    const objectsToBackup: FabricObject[] = [];

    resToPaste.forEach((obj, index) => {
      // Переменные для расчета позиции
      let left, top;

      // Получаем информацию о origin объекта
      const originX = obj.originX || 'left';
      const originY = obj.originY || 'top';

      // Получаем размеры объекта
      const objWidth = obj.getScaledWidth ? obj.getScaledWidth() : obj.width || 0;
      const objHeight = obj.getScaledHeight ? obj.getScaledHeight() : obj.height || 0;

      if (options?.insertCoordinates) {
        // Если заданы координаты вставки, учитываем origin объекта
        const { left: insertLeft, top: insertTop } = options.insertCoordinates;

        // Корректируем позицию в зависимости от origin
        if (originX === 'center') {
          left = insertLeft;
        } else if (originX === 'right') {
          left = insertLeft + objWidth / 2;
        } else {
          // left
          left = insertLeft - objWidth / 2;
        }

        if (originY === 'center') {
          top = insertTop;
        } else if (originY === 'bottom') {
          top = insertTop + objHeight / 2;
        } else {
          // top
          top = insertTop - objHeight / 2;
        }

        // Добавляем смещение для каждого последующего объекта
        left += index * defaultOffsetX;
        top += index * defaultOffsetY;
      } else {
        // Если координаты не заданы, используем существующие с добавлением смещения
        const baseOffsetX = (index + 1) * defaultOffsetX;
        const baseOffsetY = (index + 1) * defaultOffsetY;

        // Получаем текущие координаты
        const currentLeft = obj.left || 0;
        const currentTop = obj.top || 0;

        // Рассчитываем смещение с учетом origin и размеров объекта
        // Цель: объект должен быть визуально смещен на baseOffset от своей исходной позиции,
        // при этом всегда оставаясь в видимой области
        if (originX === 'center') {
          // Для объектов с центральной точкой привязки
          // Нужно убедиться, что левый край объекта не выходит за пределы видимой области
          left = currentLeft + baseOffsetX;

          // Проверяем, не находится ли левый край объекта слишком близко к краю канваса
          const leftEdge = left - objWidth / 2;
          if (leftEdge < baseOffsetX) {
            // Если левый край объекта оказывается ближе к краю канваса, чем baseOffsetX,
            // то сдвигаем объект вправо, чтобы обеспечить минимальный отступ
            left += baseOffsetX - leftEdge;
          }
        } else if (originX === 'right') {
          // Для объектов с правой точкой привязки
          // Нужно убедиться, что левый край объекта не выходит за пределы видимой области
          left = currentLeft + baseOffsetX;

          // Проверяем, не находится ли левый край объекта слишком близко к краю канваса
          const leftEdge = left - objWidth;
          if (leftEdge < baseOffsetX) {
            // Если левый край объекта оказывается ближе к краю канваса, чем baseOffsetX,
            // то сдвигаем объект вправо, чтобы обеспечить минимальный отступ
            left += baseOffsetX - leftEdge;
          }
        } else {
          // 'left'
          // Для объектов с левой точкой привязки
          // Просто добавляем смещение, так как left уже соответствует левому краю
          left = currentLeft + baseOffsetX;
        }

        // Аналогичная логика для вертикального смещения
        if (originY === 'center') {
          top = currentTop + baseOffsetY;

          const topEdge = top - objHeight / 2;
          if (topEdge < baseOffsetY) {
            top += baseOffsetY - topEdge;
          }
        } else if (originY === 'bottom') {
          top = currentTop + baseOffsetY;

          const topEdge = top - objHeight;
          if (topEdge < baseOffsetY) {
            top += baseOffsetY - topEdge;
          }
        } else {
          // 'top'
          top = currentTop + baseOffsetY;
        }
      }

      const newId = generateObjectId(targetCanvasId);
      obj.set({
        left,
        top,
        id: newId,
        // dirty: true,
        objectCaching: true, //false
      });
      if (obj.type.toLowerCase() === 'group' && obj instanceof Group) {
        if ('category' in obj) {
          if (obj.category !== undefined && obj.category.toLowerCase() === 'bullet') {
            obj._objects.forEach((obj) => {
              obj.set('idGroup', newId);
              obj.set('id', generateObjectId(targetCanvasId));
            });
          }
        }
      }

      objectsToBackup.push(obj.toJSON());
    });

    //integration with backups
    if (objectsToBackup.length > 0) {
      const canvasStore = useCanvasStore.getState();
      const canvasState = canvasStore.getCanvasState(targetCanvasId);

      if (canvasState) {
        //remove future backups
        canvasState.backups = canvasState.backups.slice(0, canvasState.currentStateIndex + 1);
        const {
          objects: currentObjects,
          background: currentBackground,
          version: currentVersion,
        } = canvasState.backups[canvasState.currentStateIndex];
        //add new backup
        const newBackup = {
          background: currentBackground,
          version: currentVersion,
          objects: [...currentObjects, ...objectsToBackup],
        };
        canvasState.backups.push(newBackup);

        //update current index
        canvasState.currentStateIndex = canvasState.backups.length - 1;

        //optional, if you need to save backup limit
        if (canvasState.backups.length > canvasState.backupDepth) {
          canvasState.backups.shift();
          canvasState.currentStateIndex--;
        }

        console.debug('Backup updated', canvasState.backups);
        loadObjectsWithLayers(canvasState.fabricInstance, targetCanvasId, newBackup);
      }
    }
  }
};
