import { CrossAltIcon } from '@app/components/ui/icons';
import { cvaIcon } from '@app/shared/ui/elements';
import { Dialog, DialogContent } from '@app/shared/ui/dialog';
import { Close } from '@radix-ui/react-dialog';
import { FC, useEffect } from 'react';
import { Button } from '@app/shared/ui/button';

export const WelcomeMessageModal: FC<{
  message: string;
  isOpen: boolean;
  onClose: () => void;
  buttonText?: string;
}> = ({ message, isOpen, onClose, buttonText = 'Ok' }) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest('[data-js="welcome-modal-close"]')) {
        onClose();
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [onClose]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className="max-w-[49.625rem]"
        customCloseIcon={
          <Close
            aria-label="Close"
            className="absolute right-2 top-2 transition-colors disabled:pointer-events-none text-general_text_MII_secondary_i hover:text-general_text_MII_secondary_ii focus-visible:outline"
          >
            <CrossAltIcon className={cvaIcon({ size: 'small' })} />
            <span className="sr-only">Close</span>
          </Close>
        }
      >
        <div className="flex flex-col gap-4 p-6">
          <h4 className="font-medium leading-none text-general_text_MII_default">Welcome!</h4>
          <div className="welcome-message-content text-sm text-general_text_MII_secondary_i flex flex-col gap-3">
            <div dangerouslySetInnerHTML={{ __html: message }} />
            <Button variant="primary" size="text16" onClick={onClose} className="p-2">
              {buttonText}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
