import { FC, PropsWithChildren, ReactNode } from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '@app/shared/ui/popover';

import { cvaPopoverContent } from './header-popover-styles';

type HeaderPopoverProps = PropsWithChildren<{
  Trigger: ReactNode;
}>;

export const HeaderPopover: FC<HeaderPopoverProps> = (props) => {
  const { Trigger, children } = props;

  return (
    <Popover>
      <PopoverTrigger asChild>{Trigger}</PopoverTrigger>
      <PopoverContent align="end" className={cvaPopoverContent()}>
        {children}
      </PopoverContent>
    </Popover>
  );
};
