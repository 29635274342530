import { cookie } from './cookie';

export const getCsrfToken = () => {
  return document.cookie.match(/csrftoken=[^;]+/g)?.[0]?.split('=')?.[1] ?? null;
};

export const prepareHeaders = (
  csrftoken: string | null = getCsrfToken(),
  isFormData: boolean = false,
) => {
  const headers = new Headers();
  headers.set('X-CSRFToken', csrftoken ?? String(cookie.get('csrftoken')));
  headers.set('X-Requested-With', 'XMLHttpRequest');

  if (!isFormData) {
    headers.set('Content-Type', 'application/json');
  }

  return headers;
};

export const retryFetch = async <T>({
  cb,
  retries = 3,
  delayBetween = 350,
}: {
  cb: () => Promise<T>;
  retries: number;
  delayBetween: number;
}): Promise<T> => {
  try {
    const res = await cb();
    return res;
  } catch (error) {
    console.error('Error in retryFetch', error);
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, delayBetween));
      return retryFetch({ cb, retries: retries - 1, delayBetween });
    } else {
      return Promise.reject(error);
    }
  }
};
