const pageRoot = {
  legal: '/legal',
};
export const route_path = {
  // Common Routes
  main: '/',
  admin: '/admin/',
  login: '/login/',
  register: '/register/',
  logout: '/logout',
  about: '/about/',
  blog: '/blog/',
  features: '/features/',
  pricing: '/pricing/',
  terms: `/terms/`,
  privacy: '/privacy/',
  cookie: '/cookie/',
  // Legal routes
  legal: `${pageRoot.legal}/`,
  legal_terms: `${pageRoot.legal}/terms/`,
  legal_privacy: `${pageRoot.legal}/privacy/`,
  legal_cookie: `${pageRoot.legal}/cookie/`,
  // Team routes
  team: '/team/',
  teamAttribute: '/team/attribute/',
  // Projects routes
  project: '/project/',
  projects: '/projects/',
  // Presentation Creation
  create: '/create/',
  setupStyle: '/create/setup-style/',
  uploadDraft: '/create/upload-draft/',
  // Workspace
  workspace: '/workspace/',
  workspaceCreate: '/workspace/create/',
  workspaceSetupStyle: '/workspace/create/setup-style/',
  workspaceUploadDraft: '/workspace/create/upload-draft/',
  // Redirects
  redirectPayment: '/redirect-payment/',
  notFound: '/404/',
};
