import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@app/app/lib';
import {
  ComponentPropsWithoutRef,
  createElement,
  FC,
  forwardRef,
  ReactNode,
  SVGProps,
  useId,
} from 'react';
import { cvaIcon } from '@app/shared/ui/elements';

const cvaRoot = cva(
  [
    'search-input-cvaRoot',
    'flex p-3 w-full gap-x-2.5 items-center rounded-lg text-general_text_MII_secondary_i',
  ],
  {
    variants: {
      variant: {
        primary: 'bg-general_background_MIII_400_dark',
        secondary: 'bg-general_background_MIII_450_dark',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
);

const cvaInput = cva([
  'search-input-cvaInput',
  'flex w-full ml-2.5 text-font_ds_title_checkbox_checkbox_16 text-general_text_MII_default',
  'focus-visible:outline-none',
  'disabled:cursor-not-allowed disabled:opacity-50 placeholder:text-general_text_MII_disabled',
]);

type InputProps = VariantProps<typeof cvaRoot> &
  ComponentPropsWithoutRef<'input'> & {
    iconBefore?: FC<SVGProps<SVGSVGElement>> | ReactNode;
    iconAfter?: FC<SVGProps<SVGSVGElement>> | ReactNode;
  };

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { variant, className, iconBefore, iconAfter, ...restProps } = props;
  const id = useId();

  return (
    <label htmlFor={id} className={cn(cvaRoot({ variant }), className)}>
      {typeof iconBefore === 'function'
        ? createElement(iconBefore, { className: cvaIcon({ size: 'medium' }) })
        : iconBefore}
      <input id={id} className={cvaInput()} ref={ref} {...restProps} />
      {typeof iconAfter === 'function'
        ? createElement(iconAfter, { className: cvaIcon({ size: 'medium' }) })
        : iconAfter}
    </label>
  );
});
Input.displayName = 'Input';

export { Input };
