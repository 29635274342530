import { Object as FabricObject, Group } from 'fabric';

export const findObjectById = (objects: FabricObject[], targetId: string): FabricObject | null => {
  for (const obj of objects) {
    if (obj.id === targetId) {
      return obj;
    }
    if (obj instanceof Group && obj.category && obj.category.toLowerCase() === 'bullet') {
      const found = findObjectById(obj.getObjects(), targetId);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
