/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Интерфейс для метаданных слайда
 */
export interface SlideMetadata {
  width: number;
  height: number;
}

/**
 * Извлекает метаданные слайда из initialCanvasData
 * @param initialCanvasData - данные холста
 * @returns объект с шириной и высотой слайда или null, если метаданные не найдены
 */
export function extractSlideMetadata(initialCanvasData: any): SlideMetadata | null {
  if (!initialCanvasData || !initialCanvasData.objects) {
    return null;
  }

  // Ищем объект с категорией 'metadata'
  for (const key in initialCanvasData.objects) {
    const obj = initialCanvasData.objects[key];
    if (obj.category === 'metadata') {
      const slideWidth = obj.slide_width || 0;
      const slideHeight = obj.slide_height || 0;

      if (slideWidth && slideHeight) {
        return {
          width: slideWidth,
          height: slideHeight,
        };
      }
    }
  }

  return null;
}

/**
 * Вычисляет масштаб холста на основе размеров контейнера и слайда
 * @param containerWidth - ширина контейнера
 * @param containerHeight - высота контейнера
 * @param slideWidth - ширина слайда
 * @param slideHeight - высота слайда
 * @returns оптимальный масштаб для отображения слайда в контейнере
 */
export function calculateCanvasScale(
  containerWidth: number,
  containerHeight: number,
  slideWidth: number,
  slideHeight: number,
): number {
  if (slideWidth === 0 || slideHeight === 0) {
    return 1;
  }

  // Вычисляем масштаб по ширине и высоте
  const scaleX = containerWidth / slideWidth;
  const scaleY = containerHeight / slideHeight;

  // Возвращаем минимальный масштаб, чтобы слайд полностью поместился в контейнер
  return Math.min(scaleX, scaleY);
}
