export const screens = {
  screens: {
    xxs: '376px',
    xs: '641px',
    sm: '769px',
    semiMd: '1025px',
    md: '1281px',
    lg: '1441px',
    xlg: '1921px',

    'xlg-max': { max: '1921px' },
    'lg-max': { max: '1441px' },
    'md-max': { max: '1281px' },
    'semiMd-max': { max: '1025px' },
    'sm-max': { max: '769px' },
    'xs-max': { max: '641px' },
    'xxs-max': { max: '3376px' },
  },
};
