import React, { ForwardedRef, forwardRef, useState, ChangeEvent, HTMLAttributes } from 'react';

type PasswordInputProps = Omit<
  HTMLAttributes<HTMLInputElement>,
  'onChange' | 'error' | 'label' | 'value'
> & {
  error: string | undefined;
  label: string;
  value?: string;
  onChange?: (value: string) => void;
};

const PasswordInput = forwardRef(
  (props: PasswordInputProps, forwardRef: ForwardedRef<HTMLInputElement>) => {
    const { error, label, value, onChange, ...rest } = props;
    const [editing, setEditing] = useState(false);

    return (
      <div
        className={
          'm_settings_input_wrap' + (error ? ' error' : '') + (editing || value ? ' editing' : '')
        }
      >
        <span className="m_settings_input_label">{label}</span>
        <input
          ref={forwardRef}
          type="password"
          value={value}
          className="m_settings_input"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value);
          }}
          onClick={() => {
            setEditing(true);
          }}
          onBlur={() => {
            setEditing(false);
          }}
          {...rest}
          required
        />
        {error && (
          <>
            <div
              className="m_settings_clear_error"
              onClick={() => {
                onChange?.('');
              }}
            ></div>
            <div className="m_settings_input_error">{error}</div>
          </>
        )}
      </div>
    );
  },
);

PasswordInput.displayName = 'PasswordInput';
export default PasswordInput;
