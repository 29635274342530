/* eslint-disable @typescript-eslint/no-explicit-any */
import { Canvas, FabricObject } from 'fabric';

import { SELECTION_STYLES } from '../config/constants';

// Type for selection style names
export type SelectionStyleName = keyof typeof SELECTION_STYLES;

/**
 * Type for control style options
 */
export type ControlStyleOptions = {
  borderColor?: string;
  cornerColor?: string;
  cornerStrokeColor?: string;
  borderOpacityWhenMoving?: number;
  borderScaleFactor?: number;
  cornerSize?: number;
  transparentCorners?: boolean;
  cornerStyle?: 'circle' | 'rect';
  cornerStrokeWidth?: number;
  rotateCorners?: boolean; // Option to rotate corners by 45 degrees
};

/**
 * Predefined styles for control points
 */
export const CONTROL_STYLES: Record<string, ControlStyleOptions> = {
  // Hollow square control points
  HOLLOW_SQUARE: {
    cornerStyle: 'rect',
    transparentCorners: true,
    cornerStrokeWidth: 2,
    cornerSize: 10,
    rotateCorners: false,
  },

  // Hollow diamond control points (rotated squares)
  HOLLOW_DIAMOND: {
    cornerStyle: 'rect',
    transparentCorners: true,
    cornerStrokeWidth: 2,
    cornerSize: 10,
    rotateCorners: true,
  },

  // Hollow circle control points
  HOLLOW_CIRCLE: {
    cornerStyle: 'circle',
    transparentCorners: false,
    cornerStrokeWidth: 1,
    cornerSize: 5,
  },

  // Filled square control points
  FILLED_SQUARE: {
    cornerStyle: 'rect',
    transparentCorners: false,
    cornerSize: 10,
    rotateCorners: false,
  },

  // Filled diamond control points (rotated squares)
  FILLED_DIAMOND: {
    cornerStyle: 'rect',
    transparentCorners: false,
    cornerSize: 10,
    rotateCorners: true,
  },

  // Filled circle control points
  FILLED_CIRCLE: {
    cornerStyle: 'circle',
    transparentCorners: false,
    cornerSize: 10,
  },
};

/**
 * Configure selection style for a specific Canvas instance
 * @param {Canvas} canvas - Canvas instance
 * @param {Object} options - Style options
 * @param {string} options.borderColor - Border color of selection
 * @param {string} options.cornerColor - Corner color
 * @param {string} options.cornerStrokeColor - Corner stroke color
 * @param {number} options.borderOpacityWhenMoving - Border opacity when moving
 * @param {number} options.borderScaleFactor - Border scale factor
 * @param {number} options.cornerSize - Size of control points
 * @param {boolean} options.transparentCorners - Transparent corners (true for hollow points)
 * @param {string} options.cornerStyle - Corner style (circle or rect)
 * @param {number} options.cornerStrokeWidth - Corner stroke width (for hollow points)
 * @param {boolean} options.rotateCorners - Whether to rotate corners by 45 degrees
 */
export const configureCanvasSelectionStyle = (canvas: Canvas, options: ControlStyleOptions) => {
  // Apply settings to all existing objects on canvas
  canvas.getObjects().forEach((obj) => {
    // Create settings object for set method
    const settings: Record<string, any> = {};

    if (options.borderColor) settings.borderColor = options.borderColor;
    if (options.cornerColor) settings.cornerColor = options.cornerColor;
    if (options.cornerStrokeColor) settings.cornerStrokeColor = options.cornerStrokeColor;
    if (options.borderOpacityWhenMoving !== undefined)
      settings.borderOpacityWhenMoving = options.borderOpacityWhenMoving;
    if (options.borderScaleFactor !== undefined)
      settings.borderScaleFactor = options.borderScaleFactor;
    if (options.cornerSize !== undefined) settings.cornerSize = options.cornerSize;
    if (options.transparentCorners !== undefined)
      settings.transparentCorners = options.transparentCorners;
    if (options.cornerStyle) settings.cornerStyle = options.cornerStyle;
    if (options.cornerStrokeWidth !== undefined)
      settings.cornerStrokeWidth = options.cornerStrokeWidth;

    // Apply all settings at once using set method
    obj.set(settings);

    // Apply rotation to control points if needed
    if (options.rotateCorners) {
      applyRotatedControls(obj);
    }
  });

  // Set up handler to apply styles to new objects
  canvas.on('object:added', (e) => {
    const obj = e.target;
    if (!obj) return;

    // Create settings object for set method
    const settings: Record<string, any> = {};

    if (options.borderColor) settings.borderColor = options.borderColor;
    if (options.cornerColor) settings.cornerColor = options.cornerColor;
    if (options.cornerStrokeColor) settings.cornerStrokeColor = options.cornerStrokeColor;
    if (options.borderOpacityWhenMoving !== undefined)
      settings.borderOpacityWhenMoving = options.borderOpacityWhenMoving;
    if (options.borderScaleFactor !== undefined)
      settings.borderScaleFactor = options.borderScaleFactor;
    if (options.cornerSize !== undefined) settings.cornerSize = options.cornerSize;
    if (options.transparentCorners !== undefined)
      settings.transparentCorners = options.transparentCorners;
    if (options.cornerStyle) settings.cornerStyle = options.cornerStyle;
    if (options.cornerStrokeWidth !== undefined)
      settings.cornerStrokeWidth = options.cornerStrokeWidth;

    // Apply all settings at once using set method
    obj.set(settings);

    // Apply rotation to control points if needed
    if (options.rotateCorners) {
      applyRotatedControls(obj);
    }
  });

  canvas.renderAll();
};

/**
 * Apply rotated controls to an object (45 degrees rotation)
 * @param {FabricObject} obj - Fabric object to apply rotated controls to
 */
export const applyRotatedControls = (obj: FabricObject) => {
  if (!obj.controls) return;

  // Get all control names
  const controlNames = Object.keys(obj.controls);

  // Apply rotation to each control
  controlNames.forEach((controlName) => {
    const control = obj.controls[controlName];
    if (control) {
      control.render = (ctx, left, top, styleOverride, fabricObject) => {
        // Save current context state
        ctx.save();

        // Translate to control center
        ctx.translate(left, top);

        // Rotate by 45 degrees
        ctx.rotate(Math.PI / 4);

        // Draw the control
        const size = control.sizeX || fabricObject.cornerSize || 10;
        const halfSize = size / 2;

        // Set styles
        ctx.fillStyle = fabricObject.cornerColor || '#FFFFFF';
        ctx.strokeStyle = fabricObject.cornerStrokeColor || fabricObject.cornerColor || '#00BA88';
        // Use any to access cornerStrokeWidth which might not be in the type definition
        const strokeWidth = (fabricObject as any).cornerStrokeWidth || 2;
        ctx.lineWidth = strokeWidth;

        // Draw square
        if (fabricObject.transparentCorners) {
          ctx.strokeRect(-halfSize, -halfSize, size, size);
        } else {
          ctx.fillRect(-halfSize, -halfSize, size, size);
        }

        // Restore context
        ctx.restore();
      };
    }
  });
};

/**
 * Apply predefined control style to Canvas
 * @param {Canvas} canvas - Canvas instance
 * @param {string} styleName - Style name from CONTROL_STYLES
 * @param {ControlStyleOptions} additionalOptions - Additional options to customize the style
 */
export const applyControlStyle = (
  canvas: Canvas,
  styleName: keyof typeof CONTROL_STYLES,
  additionalOptions: ControlStyleOptions = {},
) => {
  const styleOptions = CONTROL_STYLES[styleName];

  if (!styleOptions) {
    console.warn(`Style "${styleName}" not found in CONTROL_STYLES`);
    return;
  }

  // Merge predefined style with additional options
  const options: ControlStyleOptions = {
    ...styleOptions,
    ...additionalOptions,
  };

  // Apply style to Canvas
  configureCanvasSelectionStyle(canvas, options);
};

/**
 * Apply style to active object or group selection
 * @param {FabricObject | null} targetObject - Object to apply style to (if null, active object from canvas is taken)
 * @param {Canvas} canvas - Canvas instance
 * @param {SelectionStyleName} styleName - Style name from SELECTION_STYLES
 * @param {Partial<ControlStyleOptions>} customStyleOptions - Дополнительные опции стиля для переопределения
 */
export const applyStyleToActiveSelection = (
  targetObject: FabricObject | null,
  canvas: Canvas,
  styleName: SelectionStyleName = 'HOLLOW_CIRCLE',
  customStyleOptions: Partial<ControlStyleOptions> = {},
) => {
  if (!canvas) return;

  // Get active object from canvas
  const activeObject = targetObject || canvas.getActiveObject();
  if (!activeObject) return;

  // Get base style from constants
  const baseStyle = SELECTION_STYLES[styleName];

  // Style settings for applying
  const styleSettings: Record<string, any> = {
    borderColor: baseStyle.borderColor,
    cornerColor: baseStyle.cornerColor,
    cornerStrokeColor: baseStyle.cornerStrokeColor,
    cornerSize: baseStyle.cornerSize,
    transparentCorners: baseStyle.transparentCorners,
    borderOpacityWhenMoving: baseStyle.borderOpacityWhenMoving,
  };

  // Add cornerStyle if it exists in base style
  if ('cornerStyle' in baseStyle && baseStyle.cornerStyle) {
    styleSettings.cornerStyle = baseStyle.cornerStyle;
  }

  // Add cornerStrokeWidth if it exists in base style
  if ('cornerStrokeWidth' in baseStyle && baseStyle.cornerStrokeWidth !== undefined) {
    styleSettings.cornerStrokeWidth = baseStyle.cornerStrokeWidth;
  }

  // Apply custom options
  Object.assign(styleSettings, customStyleOptions);

  // Apply style to object
  activeObject.set(styleSettings);

  // Determine if we need to rotate control points (default for HOLLOW_DIAMOND or if explicitly set)
  const shouldRotateCorners =
    ('rotateCorners' in baseStyle && baseStyle.rotateCorners) ||
    ('rotateCorners' in customStyleOptions && customStyleOptions.rotateCorners) ||
    styleName === 'HOLLOW_DIAMOND'; // Default for HOLLOW_DIAMOND always rotate

  // If we need to rotate control points, apply rotation regardless of object type
  if (shouldRotateCorners) {
    applyRotatedControls(activeObject);
  }

  canvas.requestRenderAll();
};
