import { HTMLAttributes } from 'react';

import { cn } from '@app/app/lib';

function SkeletonWithInner({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'box-border px-5 py-2 flex justify-center items-center animate-pulse rounded bg-general_background_MIII_450_dark',
        className,
      )}
      {...props}
    >
      <div className="w-full h-full animate-pulse rounded-lg bg-general_background_MIII_500_dark" />
    </div>
  );
}

function Skeleton({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('animate-pulse rounded bg-general_background_MIII_450_dark', className)}
      {...props}
    />
  );
}

export { Skeleton, SkeletonWithInner };
