import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'rename-modal-styles-cvaRoot max-w-[50rem] bg-general_background_MIII_450_dark',
]);

export const cvaContent = cva([
  'rename-modal-styles-cvaContent',
  'p-6 flex flex-col gap-4 max-w-[50rem]',
]);

export const cvaTitle = cva([
  'rename-modal-styles-cvaTitle',
  'text-font_ds_title_title_title_20 text-general_text_MII_default font-medium',
]);

export const cvaInput = cva([
  'rename-modal-styles-cvaInput',
  'w-full p-2 border border-general_MI_300_secondary rounded-md focus:outline-none focus:ring-2 focus:ring-general_MI_500_default',
]);

export const cvaActions = cva(['rename-modal-styles-cvaActions', 'flex justify-end gap-3 mt-4']);
