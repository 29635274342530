import { configureStore } from '@reduxjs/toolkit';

import paymentsApi from '@app/store/api/payments.api';
import commonApi from '@app/store/api/common.api';

import commonSlice from './slice/common.slice';
import presentationSlice from './slice/presentation.slice';
import workspaceSlice from './slice/workspace.slice';
import { slideApi } from './api/slide.api';
import presentationApi from './api/presentation.api';
import presetApi from './api/preset.api';
import userApi from './api/user.api';
import authSlice from './slice/auth.slice';
import themeSlice from './slice/theme.slice';

import userReducer from './slice/user.slice';

export const store = configureStore({
  reducer: {
    [slideApi.reducerPath]: slideApi.reducer,
    [presentationApi.reducerPath]: presentationApi.reducer,
    [presetApi.reducerPath]: presetApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    themeState: themeSlice,
    commonState: commonSlice,
    presentationState: presentationSlice,
    authState: authSlice,
    userState: userReducer,
    workspaceState: workspaceSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      slideApi.middleware,
      presentationApi.middleware,
      presetApi.middleware,
      userApi.middleware,
      paymentsApi.middleware,
      commonApi.middleware,
    ),
});

// setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
