/* eslint-disable @typescript-eslint/no-explicit-any */
import { FabricObject, Canvas } from 'fabric';

/**
 * Restores original opacity values for objects in canvas state
 * @param state Canvas state for backup
 * @returns State with restored original opacity values
 */
export const restoreOriginalOpacitiesInState = (state: any) => {
  if (!state.objects) return state;

  const modifiedState = { ...state };
  modifiedState.objects = state.objects.map((obj: FabricObject & { originalOpacity?: number }) => {
    const modifiedObj = { ...obj };

    // If object has originalOpacity, use it
    if (modifiedObj.originalOpacity !== undefined) {
      modifiedObj.opacity = modifiedObj.originalOpacity;
      delete modifiedObj.originalOpacity;
    }

    return modifiedObj;
  });

  return modifiedState;
};

/**
 * Restores original opacity values for objects in canvas
 * @param canvas Canvas instance
 * @returns Object with objects that had temporary opacity and their temporary opacity values
 */
export const restoreOriginalOpacities = (canvas: Canvas) => {
  const objectsWithTempOpacity = canvas
    .getObjects()
    .filter((obj) => obj.originalOpacity !== undefined);
  const tempOpacityValues = objectsWithTempOpacity.map((obj) => obj.opacity);

  // Restore original values
  objectsWithTempOpacity.forEach((obj) => {
    obj.set('opacity', obj.originalOpacity);
  });

  return { objectsWithTempOpacity, tempOpacityValues };
};

/**
 * Restores temporary opacity values for objects
 * @param objects Objects to restore opacity for
 * @param opacityValues Opacity values to restore
 */
export const restoreTempOpacities = (objects: FabricObject[], opacityValues: number[]) => {
  objects.forEach((obj, index) => {
    obj.set('opacity', opacityValues[index]);
  });
};
